import { useEffect } from "react";
import classNames from "classnames";
import _ from "lodash";

import useToggle from "@hooks/use-toggle";

import { DynamicPosition } from "@components/DynamicPosition";
import TrashIcon from "@components/Icons/TrashIcon";
import DeleteModal from "@components/Modals/DeleteModal";
import Transition from "@components/Transition";

export type DropdownItem =
  | {
      type: "button" | "link";
      title: string;
      Icon: React.ElementType;
      onClick: () => void;
      variant?: "primary" | "delete"; // Styling options for items (like delete should be red)
    }
  | {
      type: "delete";
      onDelete: () => void;
      artifactType: string;
    };

interface SecondaryDropdownItemProps extends DropdownItem {
  disabled?: boolean;
}
export type SecondaryDropdownProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  buttonRef: React.MutableRefObject<any>; // Used to close dropdown when you click outside the dropdown
} & (
    | // Can pass either children (SecondaryDropdownItem) or an array of objects with props data
    { children: React.ReactNode; dropdownItems?: never }
    | { dropdownItems: DropdownItem[]; children?: never }
  );

export const SecondaryDropdownItem = ({
  type = "button",
  title,
  Icon,
  onClick,
  disabled,
  variant = "primary",
  onDelete,
  artifactType,
}: SecondaryDropdownItemProps) => {
  const defaultClassName = classNames(
    "flex justify-between items-center w-full text-left px-4 py-3 leading-5 text-black-ink hover:bg-gray-100 focus:outline-none focus:bg-gray-100",
    disabled && "pointer-events-none text-grey-800",
    (variant === "delete" || type === "delete") && "text-peach-500" // Delete option should be red
  );

  const { value: showDeleteModal, toggle: toggleDeleteModal } = useToggle();

  switch (type) {
    case "button": {
      return (
        <button className={defaultClassName} onClick={onClick}>
          {title}
          <Icon />
        </button>
      );
    }
    case "delete": {
      return (
        <DeleteModal
          show={showDeleteModal}
          toggleShow={toggleDeleteModal}
          trigger={
            <button className={defaultClassName} onClick={toggleDeleteModal}>
              Delete
              <TrashIcon />
            </button>
          }
          onDelete={onDelete}
          artifactType={artifactType}
          disableRouteBack
        />
      );
    }
    default:
      return null;
  }
};

const SecondaryDropdown = ({
  show,
  setShow,
  dropdownItems,
  children,
  className,
  buttonRef,
  ...rest
}: SecondaryDropdownProps) => {
  const closeDropdownOnOutsideClick = (e: any) => {
    if (!buttonRef?.current?.contains(e.target)) {
      document.removeEventListener("mousedown", closeDropdownOnOutsideClick);
      setShow(false);
      return;
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdownOnOutsideClick);
    return () => {
      document.removeEventListener("mousedown", closeDropdownOnOutsideClick);
    };
  }, []);

  return (
    <DynamicPosition
      shouldBeVisible={show}
      verticalBottomPosition="top-0"
      verticalTopPosition="bottom-12"
      horizontalPositionForBottom="right-0"
      horizontalPositionForTop="right-0"
    >
      <Transition
        show={show}
        className={classNames("mt-12 w-56 rounded-md shadow-lg", className)}
        {...rest}
      >
        <div className="rounded-md bg-white ring-1 ring-black/5">
          <div
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
            ref={buttonRef}
          >
            {children
              ? children
              : dropdownItems
              ? _.map(dropdownItems, (item, index) => (
                  <SecondaryDropdownItem
                    key={index}
                    type={item.type}
                    title={item.title}
                    Icon={item.Icon}
                    onClick={item.onClick}
                    variant={item?.variant}
                    onDelete={item?.onDelete}
                    artifactType={item?.artifactType}
                  />
                ))
              : null}
          </div>
        </div>
      </Transition>
    </DynamicPosition>
  );
};

export default SecondaryDropdown;
