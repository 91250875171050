import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const CalendarIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2H6V4H3V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V4H18V2H16V4H8V2ZM19 6H5V9H19V6ZM5 11H19V20H5V11Z"
      />
    </svg>
  );
};

export default CalendarIcon;
