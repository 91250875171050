import { ClientType } from "@lib/data/schemas/client";

type UseClientChatStatusType = (client: ClientType | undefined) => {
  clientHasJoined: boolean;
  joinDate?: { createdAt: Date; client: ClientType };
};

const useClientChatStatus: UseClientChatStatusType = (
  client: ClientType | undefined
) => {
  return {
    clientHasJoined: !!client?.portalJoinedAt,
    joinDate: client?.portalJoinedAt
      ? { createdAt: client.portalJoinedAt, client }
      : undefined,
  };
};

export default useClientChatStatus;
