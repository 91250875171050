import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import classNames from "classnames";
import { includes, isEmpty, kebabCase } from "lodash";

import { useAuth } from "@contexts/auth";
import { useIsMobile } from "@hooks/use-window-size";

import CheckboxIcon from "@components/Icons/CheckIcon";
import LabelIcon from "@components/Icons/LabelIcon";
import MoreIcon from "@components/Icons/MoreIcon";
import PlusIcon from "@components/Icons/PlusIcon";
import TrashIcon from "@components/Icons/TrashIcon";
import LoadingSpinner from "@components/LoadingSpinner";
import DeleteModal from "@components/Modals/DeleteModal";
import Transition from "@components/Transition";

import LabelDot from "./LabelDot";
import { colors, getOrderedLabels, getRandomizedColor } from "./utils";

const LabelsSelector = ({
  isOpen = false,
  labels,
  labelsSelected,
  onClickLabel,
  className,
  position,
  onClickCreate,
  onClickBackdrop,
  withBackdrop = false,
  asModal = false,
  updating = "",
}) => {
  const { uid } = useAuth();
  const isMobile = useIsMobile();
  const [search, setSearch] = useState("");
  const handleClickLabel = (label) => () => onClickLabel(label);
  const handleSearch = (e) => setSearch(e.target.value);
  const handleClickCreate = () => {
    onClickCreate({ title: search, color: getRandomizedColor(labels) });
  };
  const searchKey = kebabCase(search);

  const editLabelRef = useRef();
  useEffect(() => {
    const showInfo = (e) => {
      if (!editLabelRef?.current?.contains(e.target)) setEditLabel(false);
    };
    document.addEventListener("mouseup", (e) => showInfo(e));
    return () => {
      document.removeEventListener("mouseup", (e) => showInfo(e));
    };
  }, []);

  const [currentLabel, setCurrentLabel] = useState(null);
  const [newTitle, setNewTitle] = useState(null);
  const [newColor, setNewColor] = useState(null);
  const [editLabel, setEditLabel] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const isChanged = newTitle || newColor;

  useEffect(() => {
    if (!isOpen) {
      setSearch("");
    }
  }, [isOpen]);

  const updateCurrentLabel = useCallback(async () => {
    if (isChanged && currentLabel) {
      const color = newColor || currentLabel.color;
      const title = newTitle || currentLabel.title;
      await axios.patch(`/api/v1/users/${uid}/labels/${currentLabel.id}`, {
        color,
        title,
      });
      setNewColor(null);
      setNewTitle(null);
      if (editLabel) setEditLabel(false);
    }
  }, [currentLabel, editLabel, isChanged, newColor, newTitle, uid]);

  useEffect(() => {
    if (!editLabel && isChanged) {
      currentLabel && updateCurrentLabel();
    }
  }, [editLabel, currentLabel, isChanged, updateCurrentLabel]);

  const filterWithSearch = ({ title }) => {
    const titleKey = kebabCase(title);
    return includes(titleKey, searchKey);
  };

  const filteredLabels = labels?.filter(filterWithSearch) || [];
  const filteredLabelsKeys = filteredLabels.map(({ title }) =>
    kebabCase(title)
  );
  const searchMatchLabels =
    filteredLabelsKeys.length === 1 && filteredLabelsKeys[0] === searchKey;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isEmpty(search) && !searchMatchLabels) {
      handleClickCreate();
    }
  };

  const handleDelete = async () => {
    await axios.delete(`/api/v1/users/${uid}/labels/${currentLabel.id}`);
    setCurrentLabel(null);
    setShowDeleteModal(false);
  };

  const handleKeyDown = (e) =>
    e.key === "Enter" && isChanged && updateCurrentLabel();

  const renderLabel = ({ id, title, color }) => {
    const checked = labelsSelected.includes(id);
    const iconWrapperStyle = classNames(
      "w-6 h-6 border-2 flex items-center justify-center rounded-sm",
      checked ? "border-black-ink" : "border-grey-500"
    );
    const iconStyle = classNames("transform scale-125", !checked && "hidden");
    const heapEventName = `contact_labels_${checked ? "removed" : "applied"}`;
    const updatingCurrent = updating === id;
    return (
      <div
        key={`label-selector-item-${id}`}
        className={classNames(
          currentLabel?.id === id && "bg-grey-950",
          "mb-2 cursor-pointer group/label rounded-lg flex flex-row items-center justify-between font-normal text-base px-4 py-4 hover:bg-grey-950"
        )}
      >
        <div
          onClick={updatingCurrent ? undefined : handleClickLabel(id)}
          data-heap-event-name={heapEventName}
          className="flex items-center"
        >
          <LoadingSpinner
            className={classNames(
              "flex-1 w-6 flex items-center justify-center",
              updatingCurrent ? "" : "hidden"
            )}
            variant="transparent"
          />
          <span
            className={classNames(
              "shrink-0",
              iconWrapperStyle,
              updatingCurrent ? "hidden" : ""
            )}
          >
            <CheckboxIcon className={iconStyle} />
          </span>

          <LabelDot color={color} className="ml-4 shrink-0" />
          <span className="ml-2">{title}</span>
        </div>
        <div
          className={classNames(
            "group-hover/label:visible invisible",
            isMobile && "visible"
          )}
          onClick={() => {
            setCurrentLabel({ title, color, id });
            setEditLabel(true);
          }}
        >
          <MoreIcon className="text-grey-500" />
        </div>
      </div>
    );
  };

  const renderSearch = (
    <form
      className="bg-grey-950 mb-2 rounded-lg flex items-center p-4"
      onSubmit={handleSubmit}
    >
      <LabelIcon className="text-grey-500" />
      <input
        type="text"
        onChange={handleSearch}
        placeholder="Add or create label..."
        className="flex-1 bg-transparent border-none py-0 focus:outline-none focus:ring-0 text-black-ink font-normal"
      />
    </form>
  );

  const renderAddButton = !isEmpty(search) && !searchMatchLabels && (
    <button
      onClick={handleClickCreate}
      data-heap-event-name="contact_labels_created"
      className="text-base mb-2 rounded-lg font-normal text-black-ink flex w-full items-center p-4 hover:bg-grey-950 focus:outline-none focus:ring-0"
    >
      <PlusIcon className="mr-4" />
      Create new label: {search}
    </button>
  );

  const renderBackdrop = (isMobile || withBackdrop) && isOpen && (
    <div
      id="backdrop"
      data-heap-event-name={isChanged && "label_updated"}
      className={classNames(
        "fixed z-20 top-0 left-0 bottom-0 right-0",
        !asModal && "bg-grey-250 sm:bg-transparent opacity-50",
        asModal && "bg-black-ink/25"
      )}
      onClick={() => onClickBackdrop(false)}
    />
  );

  return (
    <>
      {renderBackdrop}
      <Transition
        show={isOpen}
        className={classNames(
          "fixed text-base font-normal z-30 bg-white max-w-xs shadow-equal-24 rounded-2xl p-2 pb-0",
          asModal && "m-auto inset-x-0 top:8 sm:top-1/4",
          className
        )}
        style={{ ...position }}
      >
        <div className="relative w-full h-full">
          {renderSearch}
          <div className="max-h-[220px] overflow-y-auto">
            {getOrderedLabels(filteredLabels).map(renderLabel)}
          </div>
          {renderAddButton}
          <div ref={editLabelRef}>
            {currentLabel && (
              <div
                style={isMobile ? { left: "20%" } : { left: "100%" }}
                className={classNames(
                  isMobile ? "mt-2" : "ml-4 top-0 mt-16",
                  "absolute w-[200px] bg-white overflow-hidden shadow-equal-24 rounded-lg"
                )}
              >
                <Transition show={editLabel}>
                  <div className="flex w-full">
                    <input
                      key={currentLabel.title}
                      autoFocus
                      onKeyDown={(e) => handleKeyDown(e)}
                      defaultValue={currentLabel.title}
                      onChange={(e) => setNewTitle(e.target.value)}
                      onFocus={(event) => event.target.select()}
                      className="text-base h-8 w-full pl-2 m-2 rounded-lg border border-grey-950 outline-none focus:ring-0 focus:border-action-700 bg-grey-950 focus:bg-white"
                    />
                  </div>

                  <div className="flex justify-center flex-wrap gap-1">
                    {colors.map((c) => (
                      <div
                        tabIndex="0"
                        onKeyDown={(e) => handleKeyDown(e)}
                        onClick={() => setNewColor(c)}
                        key={c}
                        className={classNames(
                          ((newColor && c === newColor) ||
                            (!newColor && c === currentLabel.color)) &&
                            "bg-grey-950",
                          "p-2 my-1 hover:bg-grey-950 cursor-pointer flex justify-center items-center rounded-lg"
                        )}
                      >
                        <LabelDot key={currentLabel.color} color={c} />
                      </div>
                    ))}
                  </div>

                  <div
                    onClick={() => {
                      setShowDeleteModal(true);
                    }}
                    className="flex items-center border-t hover:bg-grey-950 cursor-pointer"
                  >
                    <div className="flex justify-between items-center w-full py-3 px-4 text-peach-600">
                      <div>Delete</div>
                      <TrashIcon />
                    </div>
                  </div>
                </Transition>
              </div>
            )}
          </div>
        </div>
      </Transition>
      <DeleteModal
        show={showDeleteModal}
        toggleShow={setShowDeleteModal}
        onDelete={handleDelete}
        artifactType="label"
        description="This will delete the label from all associated clients and Smart Actions."
        disableRouteBack
      />
    </>
  );
};

export default LabelsSelector;
