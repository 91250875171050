import React, { FC, ReactNode } from "react";
import classNames from "classnames";

import { Button, ButtonProps } from "@components/Button";
import PlusIcon from "@components/Icons/PlusIcon";

interface AddButtonProps extends ButtonProps {
  onClick?: () => void;
  children: ReactNode;
  className?: string;
}
const AddButton: FC<AddButtonProps> = ({
  onClick,
  children,
  className,
  ...rest
}) => (
  <Button
    overrideColors
    textLeft
    onClick={onClick}
    className={classNames(
      "border hover:bg-grey-950 hover:border-transparent border-grey-900 rounded-lg w-full text-black-ink",
      className
    )}
    {...rest}
  >
    <div className="flex items-center font-normal">
      {/* eslint-disable-next-line */}
      {/* @ts-ignore */}
      <PlusIcon className="mr-3 -ml-2" />
      {children}
    </div>
  </Button>
);

export default AddButton;
