import { FC, useState } from "react";

import CollapsedHeader from "@components/DisplayDetails/CollapsedHeader";

export interface ToggleCardProps {
  icon: string;
  title: string;
  excerpt: string;
  content: string;
}

const ToggleCard: FC<ToggleCardProps> = ({ icon, title, excerpt, content }) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const leftElement = (
    <div className="flex gap-4 items-center">
      <div>
        <img src={icon} alt="" />
      </div>
      <div className="flex flex-col grow text-left">
        <h4>{title}</h4>
        <p className="text-grey-500">{excerpt}</p>
      </div>
    </div>
  );

  return (
    <div className="border border-grey-900 rounded-lg text-sm mt-2 mb-2 p-4">
      <CollapsedHeader
        collapsed={collapsed}
        toggleCollapsed={setCollapsed}
        leftElement={leftElement}
        className="text-grey-500"
      />

      {!collapsed && (
        <div
          className="mt-4 pt-4 border-t border-grey-900 text-grey-500 webflow-content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
    </div>
  );
};

export default ToggleCard;
