import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import { kebabCase, noop } from "lodash";

const HighlightedTextContext = createContext<{
  highlight: string;
  setHighlight: Dispatch<SetStateAction<string>>;
  resetSearch: () => void;
  filter: (items: any[] | undefined) => any[] | undefined;
}>({
  highlight: "",
  setHighlight: (() => "") as Dispatch<SetStateAction<string>>,
  resetSearch: noop,
  filter: (items) => items,
});

export const useHighlightedTextContext = () =>
  useContext(HighlightedTextContext);

export const HighlightedTextContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [highlight, setHighlight] = useState("");

  const resetSearch = useCallback(() => {
    setHighlight("");
  }, []);

  const filter = useCallback(
    (items: any[] | undefined) => {
      const searchActive = highlight.length > 1;
      if (!searchActive || !items) return items;
      return items.filter((item) =>
        kebabCase(item.searchKey).includes(kebabCase(highlight))
      );
    },
    [highlight]
  );

  return (
    <HighlightedTextContext.Provider
      value={{
        setHighlight,
        resetSearch,
        highlight,
        filter,
      }}
    >
      {children}
    </HighlightedTextContext.Provider>
  );
};
