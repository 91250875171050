import { FC, useEffect, useState } from "react";
import axios from "axios";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";
import useDeleteClient from "@hooks/use-client-delete";
import useContact from "@hooks/use-contact";
import useSnackbar from "@hooks/use-snackbar";
import { displayNameFromContact } from "@lib/contacts";

import SmallBanner from "@components/Banner/SmallBanner";
import EventInstantIcon from "@components/Icons/EventInstantIcon";
import LoopIcon from "@components/Icons/LoopIcon";
import SmallModal from "@components/Modals/SmallModal";

type DeleteClientModalProps = {
  show?: boolean;
  toggleShow?: (val: boolean) => void;
  clientId: string | undefined;
};

const DeleteClientModal: FC<DeleteClientModalProps> = ({
  show,
  toggleShow,
  clientId,
}) => {
  const { oid } = useAuth();
  const { contact } = useContact(clientId);
  const displayName = displayNameFromContact(contact);
  const firstName = displayNameFromContact(contact, true);
  const [hasActiveSubscriptions, setHasActiveSubscriptions] =
    useState<boolean>(false);
  const { deleteClient, isLoadingDelete: isLoading } = useDeleteClient({
    oid,
    clientId,
  });
  const snackbar = useSnackbar();
  const router = useRouter();

  const handleDelete = async (): Promise<void> => {
    if (!oid || !clientId) return;

    try {
      await deleteClient();
      snackbar.showMessage("Contact deleted");
      router.push(`/contacts`);
    } catch (e) {
      snackbar.showWarning("Something went wrong", "Try again later");
    }
  };

  useEffect(() => {
    if (!oid || !clientId) return;

    (async () => {
      try {
        const response = await axios.get(
          `/api/v1/users/${oid}/clients/${clientId}/subscriptions`
        );
        const subscriptions = response.data;

        setHasActiveSubscriptions(subscriptions.length > 0);
      } catch (error) {
        console.log("Error fetching subscriptions", error);
      }
    })();
  }, [oid, clientId]);

  return (
    <SmallModal
      show={show}
      toggleShow={toggleShow}
      icon={EventInstantIcon}
      disableClose={true}
      iconColor="peach"
      title="Are you sure?"
      description={`Deleting ${displayName} will also remove all linked artifacts, such as appointments, notes, forms, and invoices.`}
      onActionLoading={isLoading}
      onAction={handleDelete}
      onActionText={`Delete ${firstName}`}
      heapModalName="assign_client_modal"
      isActionDisabled={isLoading}
      isCloseAfterPrimaryActionDisabled
    >
      {hasActiveSubscriptions && (
        <SmallBanner
          className="text-action-300 mb-4"
          items={[
            {
              Icon: LoopIcon,
              text: "All active payment plans or subscriptions will be canceled.",
            },
          ]}
          variant="alert"
        />
      )}
    </SmallModal>
  );
};

export default DeleteClientModal;
