import React, { FC } from "react";
import classNames from "classnames";

import { IconType } from "@lib/shared-types";

export type OptionType = {
  value: string | number;
  title: string | JSX.Element;
  description?: string;
  icon?: IconType;
  disabled?: boolean;
};

interface OptionProps {
  className?: string;
  option: OptionType;
}

export const Option: FC<OptionProps> = ({ className, option }) => {
  if (!option) return null;
  return (
    <div
      className={classNames(
        "flex items-center",
        option.icon && "py-1",
        className
      )}
    >
      {option.icon && (
        <div className="pr-4 shrink-0">
          <img className="w-8" src={`/icons/${option.icon}.svg`} />
        </div>
      )}
      <div className="truncate">
        <div className={option.description && "font-medium"}>
          <span className="block truncate">{option.title}</span>
        </div>
        {option.description && (
          <div className="text-grey-500">
            <span className="block truncate">{option.description}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Option;
