import { FC } from "react";

import AlertIcon from "@components/Icons/AlertIcon";

import SmallModal from "./SmallModal";

interface InviteClientModalProps {
  clientName: string;
  show: boolean;
  toggleShow: (value: boolean) => void;
  setMedium: (value: string) => void;
}

const ClientWarningModal: FC<InviteClientModalProps> = ({
  clientName,
  show,
  toggleShow,
  setMedium,
}) => {
  return (
    <SmallModal
      show={show}
      toggleShow={toggleShow}
      icon={AlertIcon}
      iconColor="action"
      title={`${clientName} has not yet joined the client portal`}
      description="To send via portal, you’ll need to first invite this client to their client portal with you."
      onAction={() => setMedium("email")}
      onActionText="Send via email"
    />
  );
};

export default ClientWarningModal;
