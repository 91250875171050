import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const LeftArrowIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.93929 11.6464C5.74403 11.8417 5.74403 12.1583 5.93929 12.3535L10.1819 16.5962C10.3772 16.7914 10.6938 16.7914 10.889 16.5962L11.5961 15.8891C11.7914 15.6938 11.7914 15.3772 11.5961 15.1819L9.41417 13H17.5C17.7761 13 18 12.7761 18 12.5V11.5C18 11.2238 17.7761 11 17.5 11H9.41417L11.5961 8.81798C11.7914 8.62272 11.7914 8.30614 11.5961 8.11088L10.889 7.40377C10.6938 7.20851 10.3772 7.20851 10.1819 7.40377L5.93929 11.6464Z" />
    </svg>
  );
};

export default LeftArrowIcon;
