import { useEffect, useState } from "react";

type CopyToClipboardType = (text: string) => void;

type UseCopyToClipboardType = {
  copyToClipboard: CopyToClipboardType;
  isCopyLabelVisible: boolean;
};
export default function useCopyToClipboard(): UseCopyToClipboardType {
  const [isCopyLabelVisible, setIsCopyLabelVisible] = useState(false);

  const copyToClipboard: CopyToClipboardType = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopyLabelVisible(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCopyLabelVisible(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [isCopyLabelVisible]);

  return {
    copyToClipboard,
    isCopyLabelVisible,
  };
}
