import { useMemo } from "react";

import { useContactSearchInfinite } from "./use-search";

const DEFAULT_PAGE_SIZE = 20;

/**
 * Drop in replacement for useSearchExtendedContacts that uses the new search.
 * It has the same API surface and maps the response to the same legacy shape for use in the UI.
 */
export const useContactSearch = (
  oid: string | undefined,
  orderBy: "name" | "activity",
  optionalProps: {
    searchKey?: string;
    clientType?: string;
    assigneeId?: string;
    excludeArchived?: boolean;
  } = {}
) => {
  const { searchKey, assigneeId, clientType, excludeArchived } = optionalProps;

  // Map the orderBy prop to the format used in the new search.
  const sortBy =
    orderBy === "name"
      ? [{ field: "name", direction: "asc" as const }]
      : orderBy === "activity"
      ? [{ field: "latestActivity.date", direction: "desc" as const }]
      : undefined;

  // assigneeId and clientType are optional filters.
  // We map them to the format used in the new search.
  const filterBy = [
    ...(assigneeId ? [{ field: "assigneeIds", value: assigneeId }] : []),
    { field: "clientType", value: clientType ?? "individual" },
    ...(excludeArchived ? [{ field: "status", value: "active" }] : []),
  ];

  const { data, isLoading, loadMore, mutate } = useContactSearchInfinite(oid, {
    query: searchKey,
    filterBy,
    sortBy,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  // Map the new search response to the same shape as the legacy search response.
  const clients = useMemo(() => {
    const documents = data?.hits?.map((hit) => hit.document);

    return documents?.map((doc) => ({
      ...doc,
      firstName: doc.name,
      email: doc.emails[0],
      latestMergedActivity: doc.latestActivity,
      labels: doc.labels?.map((label) => label.id) ?? [],
    }));
  }, [data]);

  return {
    data: clients,
    loading: isLoading,
    loadMore,
    mutate,
  };
};
