import React, { FC } from "react";
import classNames from "classnames";

import { Button } from "@components/Button";
import TrashIcon from "@components/Icons/TrashIcon";

interface TrashButtonProps {
  onClick: () => void;
  className?: string;
  size?: "default" | "small";
}

const TrashButton: FC<TrashButtonProps> = ({
  // @TODO: this is set as small because the component was created with the
  //        prop `small` setted hardcoded in this component. It needs improvement.
  size = "small",
  className,
  onClick,
  ...rest
}) => (
  <Button
    overrideColors
    square
    small={size === "small"}
    className={classNames("bg-peach-950 hover:bg-peach-800", className)}
    onClick={onClick}
    {...rest}
  >
    <TrashIcon className="text-peach-500" />
  </Button>
);

export default TrashButton;
