import { PackageSchedulerType } from "api-services/definitions/package-instances";

import { PackageInstanceType } from "@lib/data/schemas/package-instance";
import pluralHelper from "@lib/utils/pluralHelper";

import {
  countPackageAppointments,
  frequencyCopies,
} from "@components/Package/utils";
import { getPackageInstanceInfo } from "@components/PackageInstance/utils";

export const progressText = (
  packageInstance: PackageInstanceType,
  schedulers: PackageSchedulerType[],
  fromCycle?: number
) => {
  const {
    isContentTypeTime,
    totalAppointmentsConsumedInMinutes,
    totalSessionsConsumedInTotalAppointments,
    formattedTimeConsumed,
    isCompleted,
    isRollOver,
    isPackageTypeRecurring,
    isUsagePackage,
  } = getPackageInstanceInfo(packageInstance, schedulers, true, fromCycle);

  const currentStep = isContentTypeTime
    ? totalAppointmentsConsumedInMinutes
    : totalSessionsConsumedInTotalAppointments;

  const maxAllowedSteps = countPackageAppointments(
    packageInstance,
    isRollOver ? null : "intervals",
    undefined,
    true
  );

  const isPackageTypeRecurringTimeBased =
    isPackageTypeRecurring && isContentTypeTime;

  const maxAllowedStepsNumber =
    typeof maxAllowedSteps === "string"
      ? Number(maxAllowedSteps.replace(/\D/g, ""))
      : maxAllowedSteps;
  let maxAllowedTime: number | undefined = undefined;

  if (isPackageTypeRecurringTimeBased) {
    maxAllowedTime =
      maxAllowedStepsNumber < 1.0
        ? maxAllowedStepsNumber * 60
        : maxAllowedStepsNumber;
  }

  const recurringTimeBasedSuffix = isPackageTypeRecurringTimeBased
    ? maxAllowedStepsNumber < 1.0
      ? "minutes"
      : packageInstance?.timeType
    : "";

  const baseText = isContentTypeTime
    ? `${formattedTimeConsumed} of ${
        maxAllowedTime ?? maxAllowedSteps
      } ${recurringTimeBasedSuffix}`
    : `${currentStep} of ${maxAllowedSteps} appointments`;

  const bookedText = `Booked ${baseText}`;
  const completedText = `Completed: ${baseText}`;
  const usageBaseText = isContentTypeTime
    ? `${formattedTimeConsumed}`
    : `${pluralHelper(currentStep, "appointment")}`;
  const usageText = `Booked ${usageBaseText}`;
  return isUsagePackage ? usageText : isCompleted ? completedText : bookedText;
};

export const recurringText = (
  packageInstance: PackageInstanceType,
  schedulers: PackageSchedulerType[],
  fromCycle?: number
) => {
  const { isContentTypeTime, isRollOver, isPackageTypeRecurring } =
    getPackageInstanceInfo(packageInstance, schedulers, true, fromCycle);

  if (!isPackageTypeRecurring || !packageInstance?.frequency) return "";

  let total = packageInstance.frequency?.total || 0;
  const isLessThanOneHour = total < 1.0;
  if (isLessThanOneHour) {
    total *= 60;
  }
  const frequencyType = packageInstance.frequency?.type || "months";
  const frequency = frequencyCopies[frequencyType].abbreviation;

  const rolloverStr = isRollOver ? "+" : "";
  const contentTypeStr = isContentTypeTime
    ? isLessThanOneHour
      ? "min"
      : "h"
    : "";

  return `(${rolloverStr}${total}${contentTypeStr}/${frequency})`;
};
