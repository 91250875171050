import React, { FC } from "react";
import { Transition } from "@headlessui/react";
import classNames from "classnames";

interface MenuProps {
  className?: string;
  show: boolean;
  children?: React.ReactNode;
}

const Menu: FC<MenuProps> = ({ className, show, children }) => {
  return (
    <Transition
      show={show}
      enter="Menu-opacity ease-linear duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="Menu-opacity ease-linear duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className={classNames(className, "rounded-md shadow-lg")}
    >
      {children}
    </Transition>
  );
};

export default Menu;
