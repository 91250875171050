import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";

import useAccount from "@hooks/use-account";
import useContact from "@hooks/use-contact";
import useSnackbar from "@hooks/use-snackbar";
import { GenericError } from "@lib/api/errors/generic-error";

import { Button } from "@components/Button";
import ClientPicker from "@components/ClientPicker";
import { Editor } from "@components/Editor/Editor";
import TextFieldForm from "@components/Form/TextFieldForm";
import { ModalContent, ModalTitle } from "@components/Modal";

import "react-quill/dist/quill.bubble.css";

const CoachGeneratedEmail = ({
  onGoBack,
  setEmailOpen,
  body,
  selectedClientId,
  subject,
  user,
  handleEmailShare,
  setShow,
  onSelectClient,
  GoBack,
  clientPicker,
  dataHeapEventName,
  variant,
}) => {
  const { account } = useAccount();
  const { contact } = useContact(selectedClientId);
  const snackbar = useSnackbar();

  const sender = account ?? user;

  const methods = useForm({ mode: "onTouched" });
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = methods;
  const [isLoading, setIsLoading] = useState(false);
  const [emailValue, setEmailValue] = useState(body);

  useEffect(() => {
    setValue("Subject", subject);
    setValue("EmailMessage", body);
  }, []);

  const handleSendEmail = async (data) => {
    setIsLoading(true);
    const emailData = {
      coach_email: sender.email,
      coach_first_name: sender.firstName,
      coach_full_name: sender.firstName + " " + sender.lastName,
      email_title: data.Subject,
      email_content: emailValue,
      client_email: contact.email,
      chat_url: `https://practice.do/me/${user.slug}/clients/${contact.id}/messaging`,
      coach_id: user.id,
    };
    if (variant === "chat") delete emailData.chat_url;
    try {
      await axios.post("/api/v1/coachGeneratedEmail", emailData);
      snackbar.showMessage(`Email sent to ${contact.firstName}`);
    } catch (e) {
      console.log(e);
      throw new GenericError("Error sending CoachGeneratedEmail");
    }
    if (variant === "back") {
      handleEmailShare("");
      setEmailOpen(false);
    }
    setIsLoading(false);
    setShow(false);
  };

  return (
    <>
      <div className="flex self-start justify-between items-center w-full">
        <div className="flex items-center">
          <div>
            {variant === "back" && (
              <GoBack
                onClick={(event) => {
                  onGoBack(event);
                  setEmailOpen(false);
                }}
              />
            )}
          </div>
          <ModalTitle
            className={
              variant === "back"
                ? "pl-2 md:pl-4 text-xl md:text-3xl"
                : "text-3xl"
            }
          >
            Send via email
          </ModalTitle>
        </div>
        <Button
          onClick={handleSubmit(handleSendEmail)}
          disabled={!selectedClientId}
          isLoading={isLoading}
          primary
          data-heap-event-name={dataHeapEventName}
        >
          <div className="flex">Send</div>
        </Button>
      </div>
      <ModalContent className="w-full">
        {clientPicker && (
          <ClientPicker
            contactId={selectedClientId}
            handleClientSelection={onSelectClient}
            className="text-left mt-6"
          />
        )}
        <div className="text-left pb-1 text-sm pt-4 text-gray-500">Subject</div>
        <TextFieldForm
          name="Subject"
          register={register}
          placeholder="Enter a subject"
          errors={errors}
          required
        />
        <div className="text-left pb-1 text-sm pt-4 text-gray-500">Message</div>
        <div
          id="bounds"
          className="border border-grey-900 rounded-md whitespace-pre-line overflow-visible"
        >
          <Editor
            kind="email"
            value={emailValue}
            onChange={setEmailValue}
            bounds="#bounds"
          />
        </div>
      </ModalContent>
    </>
  );
};

// TODO Use modal up there
export const EmailComposeModal = ({
  selectedClientId,
  subject,
  body,
  clientPicker,
  titleClassName,
  preTitle,
  title,
  handleSendEmail,
  hasSubject,
  onSelectClient,
}) => {
  const methods = useForm({
    mode: "onTouched",
    defaultValues: { subject, body },
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
    formState,
    control,
  } = methods;

  return (
    <>
      <div className="flex self-start justify-between items-center w-full">
        <div className="flex items-center">
          {preTitle}
          <ModalTitle className={titleClassName}>{title}</ModalTitle>
        </div>
        <Button
          onClick={handleSubmit(handleSendEmail)}
          disabled={!selectedClientId}
          isLoading={formState.isSubmitting}
          primary
        >
          <div className="flex">Send</div>
        </Button>
      </div>
      <ModalContent className="w-full">
        {clientPicker && (
          <ClientPicker
            contactId={selectedClientId}
            handleClientSelection={onSelectClient}
            className="text-left mt-6"
          />
        )}
        {hasSubject && (
          <>
            <div className="text-left pb-1 text-sm pt-4 text-gray-500">
              Subject
            </div>
            <TextFieldForm
              name="subject"
              register={register}
              placeholder="Enter a subject"
              errors={errors}
            />
          </>
        )}
        <div className="text-left pb-1 text-sm pt-4 text-gray-500">Message</div>
        <div
          id="bounds"
          className="border border-grey-900 rounded-md whitespace-pre-line overflow-visible"
        >
          <Controller
            control={control}
            name="body"
            render={({ field: { value, onChange } }) => (
              <Editor
                bounds="#bounds"
                kind="email"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </div>
      </ModalContent>
    </>
  );
};

export default CoachGeneratedEmail;
