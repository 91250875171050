import { useContext, useEffect, useMemo } from "react";
import { Channel } from "stream-chat";

import { MessagingContext } from "@contexts/messaging";

import { useContacts } from "./data/clients";
import { useUserClient } from "./use-contact";

export const checkCoachJoined = async (channel: Channel, id: string) => {
  const { results } = await channel.search(
    {
      "user.id": { $eq: id },
    },
    {
      limit: 1,
      offset: 0,
    }
  );

  return !!results.length;
};

export const useHasContactJoined = (
  organizationId?: string,
  contactId?: string
) => {
  const { client } = useUserClient(organizationId, contactId);

  return !!client?.portalJoinedAt;
};

export const useUnreadChannels = () => {
  const { channels } = useContext(MessagingContext);
  const { active: contacts } = useContacts();

  const unreadChannels = useMemo(() => {
    if (!contacts || !channels) return {};

    const contactIds = contacts.map((c) => c.id);
    return Object.values(channels).reduce((prev, current) => {
      const { unreadCount, last_message_at: lastMessageAt } = current.state;

      if (!unreadCount || !current.id || !contactIds.includes(current.id)) {
        return prev;
      }
      return { ...prev, [current.id]: { unreadCount, lastMessageAt } };
    }, {});
  }, [channels, contacts]);

  const count = Object.keys(unreadChannels).length;

  return {
    unreadChannels,
    count,
    hasUnread: !!count,
  };
};

// TODO decouple `unreadChannels` from use chat
const useChat = (channelId: string) => {
  const { connection, error, channels, queryChannel } =
    useContext(MessagingContext);

  useEffect(() => {
    if (channels && connection && channelId && !channels[channelId]) {
      queryChannel(channelId);
    }
  }, [channelId, channels, connection]);

  return {
    connection,
    error,
    channels,
    channel: channels?.[channelId],
  };
};

export default useChat;
