import { useAuth } from "@contexts/auth";
import { useUpdateSWRCache } from "@hooks/use-update-swr-cache";
import { ClientType } from "@lib/data/schemas/client";

export type UpdateContactPayload = {
  contactId: string;
  data: Partial<ClientType>;
};

export const useUpdateContactsCache = () => {
  const { oid } = useAuth();

  const { updateSWRCache } = useUpdateSWRCache();

  const updateCache = async (updates: UpdateContactPayload[]) => {
    if (!oid) return;

    const contactsQueryKey = `/api/v1/organizations/${oid}/contacts?`;

    const formattedUpdates = updates.map((u) => ({
      id: u.contactId,
      data: u.data,
    }));
    await updateSWRCache(contactsQueryKey, formattedUpdates);

    for (const update of formattedUpdates) {
      const contactQueryKey = `/api/v1/organizations/${oid}/contacts/${update.id}`;
      await updateSWRCache(contactQueryKey, [update]);
    }
  };

  return {
    updateCache,
  };
};
