import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const ArchiveIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15 12H9V14H15V12Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 11H4V21H20V11H20.5C20.7761 11 21 10.7761 21 10.5V5.5C21 5.22386 20.7761 5 20.5 5H3.5C3.22386 5 3 5.22386 3 5.5V10.5C3 10.7761 3.22386 11 3.5 11ZM5 7V9H19V7H5ZM6 19V11H18V19H6Z"
      />
    </svg>
  );
};

export default ArchiveIcon;
