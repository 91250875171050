import { useState } from "react";
import { components, MenuListProps } from "react-select";
import classNames from "classnames";

import { useCheckScreenSize } from "@hooks/use-window-size";

import {
  ParentOptionType,
  ParentType,
} from "@components/Form/SelectForm/types";
import CloseIcon from "@components/Icons/CloseIcon";
import LeftArrowIcon from "@components/Icons/LeftArrowIcon";
import SearchIcon from "@components/Icons/SearchIcon";

import ParentOption from "./ParentOption";

type ExtendedCustomMenuListProps = MenuListProps & {
  onOptionClick?: () => void;
  parents?: ParentOptionType[];
  label?: string;
  isSearchable?: boolean;
  backText?: string;
  emptyMessage: string;
  maxHeight?: number;
  showDivider?: boolean;
  type?: string;
  customMenuListClassNames?: string;
  nestedType: ParentType | null;
  setNestedType: (value: ParentType | null) => void;
};

const ParentList: React.FC<ExtendedCustomMenuListProps> = (props) => {
  const [customSearchVal, setCustomSearchVal] = useState("");
  const { isMD } = useCheckScreenSize();

  const {
    children,
    isSearchable,
    parents,
    label,
    onOptionClick,
    emptyMessage,
    backText,
    maxHeight,
    type,
    showDivider,
    customMenuListClassNames,
    nestedType,
    setNestedType,
    ...rest
  } = props;

  const parentTypes = parents?.map((p) => p.type);

  const currentOptionSet = Array.isArray(children)
    ? children
        .map((c: JSX.Element) => {
          const optionData = c.props.data.extra;
          if (
            (nestedType && optionData?.type !== nestedType) ||
            (!nestedType && parentTypes?.includes(optionData?.type)) ||
            (customSearchVal &&
              !optionData?.search
                .toLowerCase()
                .includes(customSearchVal.toLocaleLowerCase()))
          )
            return null;
          return c;
        })
        .filter(Boolean)
    : [];

  const inputHandler = (
    e:
      | React.TouchEvent<HTMLInputElement>
      | React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    e.stopPropagation();
    const target = e.target as HTMLInputElement;
    target.focus();
  };

  const touchHandler = (
    e: React.TouchEvent<HTMLDivElement>,
    value: ParentType | null
  ) => {
    e.stopPropagation();
    setTimeout(() => {
      setNestedType(value);
    }, 500);
  };

  const renderBackToList = (
    <div
      onClick={() => setNestedType(null)}
      onTouchEnd={(e) => touchHandler(e, null)}
      className={classNames("flex gap-3 px-3 items-center py-1 cursor-pointer")}
    >
      <LeftArrowIcon />
      <h2 className="font-medium text-sm">
        {backText ? backText : `Back to ${label?.toLowerCase()} list`}
      </h2>
    </div>
  );

  const renderSearch = isSearchable && (
    <div className={classNames("px-2 mb-4")}>
      <div className="bg-grey-950 rounded-md flex space-between items-center sm:px-2 sm:py-2">
        <>
          {isMD && <SearchIcon className="text-grey-500" />}
          <input
            className="w-full border-none bg-grey-950 focus:ring-0 placeholder-text-xs"
            type="text"
            value={customSearchVal}
            onChange={(e) => setCustomSearchVal(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
            onMouseDown={inputHandler}
            onTouchEnd={inputHandler}
            placeholder={`Search ${isMD ? type : ""}`}
          />
        </>
        <div onClick={() => setCustomSearchVal("")}>
          <CloseIcon className="text-grey-500 cursor-pointer" />
        </div>
      </div>
    </div>
  );

  const renderParents = !!parents?.length && (
    <div
      className={classNames("flex flex-col gap-2", showDivider && "divide-y")}
    >
      {nestedType
        ? renderBackToList
        : parents.map((parent, idx) => (
            <div
              className={classNames(showDivider && "pt-2 first:pt-0")}
              onTouchEnd={(e) => {
                touchHandler(e, parent.type);
                if (parent.onClick) parent.onClick();
              }}
              onClick={() => {
                setNestedType(parent.type);
                if (parent.onClick) parent.onClick();
              }}
              key={`parent-${idx}`}
            >
              <ParentOption parent={parent} />
            </div>
          ))}
    </div>
  );

  const hasParents = !!parents?.length;

  return (
    <div className={classNames("pt-2", !nestedType && hasParents && "pb-2")}>
      {renderSearch}
      {renderParents}
      {!!currentOptionSet?.length && (
        <components.MenuList
          {...rest}
          maxHeight={maxHeight ?? 240}
          className={classNames(
            "z-20 !py-0",
            hasParents && "mt-2",
            customMenuListClassNames
          )}
        >
          {currentOptionSet?.length ? (
            <div
              className={classNames(hasParents && "last:mb-2")}
              onClick={() => {
                if (onOptionClick) onOptionClick();
              }}
            >
              {currentOptionSet}
            </div>
          ) : (
            <div className="text-grey-500 text-center text-sm py-3">
              {emptyMessage}
            </div>
          )}
        </components.MenuList>
      )}
    </div>
  );
};

export default ParentList;
