import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const FolderIcon: FC<SVGIconProps> = ({
  className,
  width = 24,
  height = 24,
}) => (
  <svg
    width={width}
    height={height}
    className={classNames("fill-current", className)}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 5C4.44772 5 4 5.44772 4 6V17C4 17.5523 4.44772 18 5 18H19C19.5523 18 20 17.5523 20 17V9C20 8.44772 19.5523 8 19 8H12.5352C11.8665 8 11.242 7.6658 10.8711 7.1094L9.76168 5.4453C9.57622 5.1671 9.26399 5 8.92963 5H5ZM2 6C2 4.34315 3.34314 3 5 3H8.92963C9.93269 3 10.8694 3.5013 11.4258 4.3359L12.5352 6H19C20.6569 6 22 7.34315 22 9V17C22 18.6569 20.6569 20 19 20H5C3.34315 20 2 18.6569 2 17V6Z"
      fill="currentColor"
    />
  </svg>
);

export default FolderIcon;
