import { getSchemaDefinition } from "api-services/common";
import { z } from "zod";

export const getContacts = getSchemaDefinition(
  "/api/v1/organizations/{orgId}/contacts",
  "get",
  {
    path: z.object({
      orgId: z.string(),
    }),
    query: z.object({
      searchKey: z.string().optional(),
      assigneeId: z.string().optional(),
      limit: z.string().optional(),
      cursorId: z.string().optional(),
      clientType: z.string().optional(),
      orderBy: z.enum(["name", "activity"]).optional(),
    }),
  },
  {
    description: "Get all contact ids for the currently authenticated user",
    tags: ["contacts"],
  },
  z.object({
    // @TODO: We need to make the database consistent from clients. We had to change it in order to improve the performance (WEB-8426)
    //        We should roll back this code one day when we normalize the data.
    data: z.array(
      z
        .object({
          id: z.string(),
          assigneeId: z.string().optional(),
          assigneeIds: z.string().array().optional(),
        })
        .catchall(z.any())
    ),
    cursorId: z.string().optional(),
  })
);

export const getContactsNumbers = getSchemaDefinition(
  "/api/v1/organizations/{orgId}/contacts/numbers",
  "get",
  {
    path: z.object({
      orgId: z.string(),
    }),
  },
  {
    description: "Get contact numbers for a given organization",
    tags: ["contacts"],
  },
  z.object({
    total: z.number(),
    active: z.number(),
  })
);

export const getHasAnyClient = getSchemaDefinition(
  "/api/v1/organizations/{orgId}/contacts/has-any-client",
  "get",
  {
    path: z.object({
      orgId: z.string(),
    }),
    query: z.object({
      status: z.string().optional(),
      clientType: z.string().optional(),
    }),
  },
  {
    description: "Get if a organization has any client",
    tags: ["contacts"],
  },
  z.boolean()
);

export const getContact = getSchemaDefinition(
  "/api/v1/organizations/{orgId}/contacts/{contactId}",
  "get",
  {
    path: z.object({
      orgId: z.string(),
      contactId: z.string(),
    }),
  },
  {
    description: "Get contact by ID",
    tags: ["contacts"],
  },
  z.object({
    data: z
      .object({
        id: z.string(),
      })
      .catchall(z.any()),
  })
);
