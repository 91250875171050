import { useMemo } from "react";
import { kebabCase } from "lodash";

import { useCollection } from "@contexts/data";
import { GenericError } from "@lib/api/errors/generic-error";

import { updateGroup } from "@components/Groups/utils";

import { useExtendedContacts } from "./data/clients";
import useAccessType from "./use-access-type";

const useOrgGroups = () => {
  const { data, loading } = useCollection("groups");

  const groups = useMemo(() => {
    if (!data) return;

    return data.map((group) => ({
      ...group,
      members: group.members.filter((member) => !member.removedAt),
    }));
  }, [data]);

  return {
    data: groups,
    loading,
  };
};

export default function useGroups(
  oid: string | undefined,
  justActiveGroups: boolean = true
): any {
  const { data: contacts, loading: loadingContacts } = useExtendedContacts();
  const { data: groups, loading: loadingGroups } = useOrgGroups();
  const { hasFullAccess, loading: loadingAccessType } = useAccessType();

  const now = new Date();

  const myGroups = useMemo(() => {
    if (!groups || !contacts) return;

    const contactIds = contacts.map((contact) => contact.id);

    if (hasFullAccess) return groups;

    // Only keep groups for which 'some' member's id can be found in the contactIds array
    return groups.filter((group) =>
      group.members.some((member) => contactIds.includes(member.clientId))
    );
  }, [contacts, groups]);

  const activeGroups = myGroups
    ?.filter((group) => !justActiveGroups || group.status === "active")
    .map((group) => {
      const groupMemberIds = group?.members?.map((member) => member?.clientId);
      const assigneeIds = groupMemberIds.map((memberId) => {
        const client = contacts?.find((client) => client?.id === memberId);
        return client && "assigneeId" in client ? client.assigneeId : "";
      });
      const contactsInGroup = contacts?.filter((contact) => {
        return groupMemberIds.includes(contact.id);
      });
      const searchKey = kebabCase(
        [group.title, ...(contactsInGroup ?? []).map((c) => c.searchKey)].join(
          " "
        )
      );
      return { ...group, assigneeIds, searchKey };
    });

  const getGroup = (groupId: string) =>
    myGroups && myGroups.find(({ id }) => id === groupId);

  const getClientGroups = (id: string) => {
    const clientGroups = myGroups?.filter(({ members }) =>
      members.some((member) => member.clientId === id)
    );
    return clientGroups;
  };

  const handleGroupMember = (clientId: string, action: string) => {
    if (!oid) return;

    const clientGroups = getClientGroups(clientId);
    clientGroups?.forEach(async (group) => {
      const index = group.members.findIndex(
        (member) => member.clientId === clientId
      );
      group.members[index].removedAt = action === "archive" ? now : undefined;
      updateGroup(group.id, group, oid);
    });
  };

  const getMemberFormat = (clientId: string) => {
    return { clientId, addedAt: now };
  };

  const addOneMember = (clientId: string, groupId: string) => {
    if (!oid) return;

    const members = getGroup(groupId)?.members;
    members?.push(getMemberFormat(clientId));
    try {
      updateGroup(groupId, { members }, oid);
    } catch {
      throw new GenericError("Error adding new client to group");
    }
  };

  const removeOneMember = (clientId: string, groupId: string) => {
    if (!oid) return;

    const members = getGroup(groupId)?.members;
    const newMembers = members?.filter(
      (member) => member.clientId !== clientId
    );
    try {
      updateGroup(groupId, { members: newMembers }, oid);
    } catch {
      throw new GenericError("Error removing client from group");
    }
  };

  return {
    groups: myGroups,
    activeGroups,
    getGroup,
    getClientGroups,
    handleGroupMember,
    addOneMember,
    removeOneMember,
    getMemberFormat,
    loading: loadingContacts || loadingGroups || loadingAccessType,
  };
}
