import _ from "lodash";

import { useHighlightedTextContext } from "@contexts/highlighted";

export const HighlightedTextFromContext: React.FC<
  Pick<Props, "className" | "text">
> = (props) => {
  const { highlight } = useHighlightedTextContext();

  return <HighlightedText {...props} highlight={highlight} />;
};

type Props = {
  text: string;
  highlight: string;
  className?: string;
};

const HighlightedText: React.FC<Props> = ({ text, highlight, className }) => {
  if (!text) return <></>;

  if (!highlight.trim()) return <span className={className}>{text}</span>;

  const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, "gi");
  const parts = text.split(regex);

  return (
    <span className={className}>
      {parts
        .filter((part) => part)
        .map((part, index) =>
          regex.test(part) ? (
            <mark key={index} className="bg-action-900">
              {part}
            </mark>
          ) : (
            <span key={index}>{part}</span>
          )
        )}
    </span>
  );
};

export default HighlightedText;
