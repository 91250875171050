import React, { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { MenuListProps, Options } from "react-select";

import SelectForm from "@components/Form/SelectForm";
import ParentList from "@components/Select/ParentList";

import { CustomOptionType, ParentOptionType, SelectFormProps } from "./types";

type SearchableMultiSelectFormProps<TOption = CustomOptionType> = {
  id?: string;
  options: Options<TOption>;
  label?: string;
  name: string;
  placeholder?: string | JSX.Element;
  parents?: ParentOptionType[];
  isDisabled?: boolean;
  defaultValue?: CustomOptionType;
  isSearchable?: boolean;
  checkBoxOption?: boolean;
  emptyMessage: string;
  type?: string;
  optionClassNames?: string;
  menuClassNames?: string;
  valueClassNames?: string;
  controlClassNames?: string;
  onInputChange?: (value: any) => void;
  required?: boolean;
  backText?: string;
  hideValue?: boolean;
  dropdownIndicator?: boolean;
  menuPlacement?: SelectFormProps["menuPlacement"];
  isClearable?: boolean;
  flatMultiValue?: boolean;
  showAllMultiValues?: boolean;
};

const SearchableMultiSelectForm = function <T = CustomOptionType>({
  id,
  options,
  defaultValue,
  isDisabled,
  label,
  name,
  placeholder,
  parents,
  isSearchable = false,
  checkBoxOption = false,
  emptyMessage,
  optionClassNames,
  menuClassNames,
  valueClassNames,
  onInputChange,
  controlClassNames,
  type,
  required = false,
  backText,
  hideValue = false,
  dropdownIndicator = true,
  menuPlacement,
  isClearable,
  flatMultiValue,
  showAllMultiValues,
}: SearchableMultiSelectFormProps<T>) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [nestedType, setNestedType] = useState<null | ParentType>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const {
    control,
    setValue,
    formState: { errors },
    register,
  } = useFormContext();

  const showMenu = (e: MouseEvent) => {
    //                                                 esc key to close
    if (!menuRef?.current?.contains(e.target as Node) || e.which === 27) {
      setIsMenuOpen(false);
      setIsFocused(false);
      return;
    } else if (!isDisabled) {
      setIsMenuOpen(true);
      setIsFocused(true);
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", showMenu);
    document.addEventListener("touchend", showMenu);
    document.addEventListener("keydown", showMenu);

    return () => {
      document.removeEventListener("mouseup", showMenu);
      document.removeEventListener("touchend", showMenu);
      document.removeEventListener("keydown", showMenu);
    };
  }, []);

  const MenuList = (props: MenuListProps) => {
    const onOptionClick = () => {
      if (!checkBoxOption) setIsMenuOpen(false);
    };
    return (
      <ParentList
        label={label}
        isSearchable={isSearchable}
        parents={parents}
        onOptionClick={onOptionClick}
        emptyMessage={emptyMessage}
        type={type}
        {...props}
        maxHeight={240}
        customMenuListClassNames={menuClassNames}
        backText={backText}
        nestedType={nestedType}
        setNestedType={setNestedType}
      />
    );
  };

  return (
    <div ref={menuRef} key={parents} id={id}>
      <SelectForm<CustomOptionType>
        options={options}
        control={control}
        errors={errors}
        defaultValue={defaultValue}
        disabled={isDisabled}
        name={name}
        placeholder={placeholder}
        label={label}
        required={required}
        selectProps={{
          isMulti: !isDisabled && checkBoxOption,
          showAllMultiValues,
          closeMenuOnSelect: !checkBoxOption,
          isSearchable: false,
          ...(isSearchable && { menuIsOpen: isMenuOpen }),
          backspaceRemovesValue: false,
          isFocused,
          hideSelectedOptions: false,
          classNames: {
            valueClassNames,
            optionClassNames,
            menuClassNames,
            controlClassNames,
          },
          hideValue,
          menuPlacement,
        }}
        indicatorSeparator={false}
        dropdownIndicator={dropdownIndicator}
        setValue={setValue}
        register={register}
        checkBoxOption={checkBoxOption}
        CustomMenuList={MenuList}
        onInputChange={onInputChange}
        isClearable={isClearable}
        flatMultiValue={flatMultiValue}
      />
    </div>
  );
};

export default SearchableMultiSelectForm;
