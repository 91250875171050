import React, { FC, ReactNode } from "react";
import classNames from "classnames";

const sizes = {
  smaller: "w-2 h-2",
  small: "w-3 h-3",
  medium: "w-4 h-4",
  large: "w-5 h-5",
};

interface Props {
  className?: string;
  size?: keyof typeof sizes;
  children?: ReactNode;
}

export const NotificationBubble: FC<Props> = ({
  className,
  size = "medium",
  children,
}) => (
  <span
    className={classNames(
      "rounded-full text-white text-xs bg-peach-600 flex items-center justify-center",
      sizes[size],
      className
    )}
  >
    {children}
  </span>
);
