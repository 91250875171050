import { useSWRConfig } from "swr";

type UpdatePayload = {
  id: string;
  data: any;
};

export const useUpdateSWRCache = () => {
  const { mutate, cache } = useSWRConfig();

  const updateSWRCache = async (
    queryKey: string,
    updates: UpdatePayload[],
    merge: boolean = true
  ) => {
    for (const update of updates) {
      const matchingQueryKeys = Array.from(cache.keys()).filter((key) =>
        key.includes(queryKey)
      );

      for (const key of matchingQueryKeys) {
        const cacheData = cache.get(key);
        const isInfinite = key?.startsWith("$inf$");
        const data = isInfinite
          ? cacheData?.data?.[0]?.data
          : cacheData?.data?.data;

        if (!data) return;

        if (!Array.isArray(data)) {
          const newData = merge ? { ...data, ...update.data } : update.data;

          await mutate(key, { data: newData }, false);
          return;
        }

        let updatedList = [...data];
        const index = data.findIndex((i: any) => (i.id ?? "") === update.id);

        if (index >= 0) {
          const current = data[index];
          const newData = merge ? { ...current, ...update.data } : update.data;

          updatedList = [
            ...data.slice(0, index),
            newData,
            ...data.slice(index + 1),
          ];

          const cursorId = cacheData?.data?.cursorId;
          const patch = { data: updatedList, ...(cursorId && { cursorId }) };
          await mutate(key, isInfinite ? [patch] : patch, false);
        }
      }
    }
  };

  return {
    updateSWRCache,
  };
};
