import { FC } from "react";
import {
  getWebflowLearnMoreItems,
  LearnMoreItemCategoryType,
} from "api-services/definitions/webflow";
import { useApiGet } from "api-services/endpoints";

import { collectionIds, learnMoreCategories } from "@lib/constants/webflow";

import ToggleCard from "@components/EmptyState/ToggleCard";
import LoadingSpinner from "@components/LoadingSpinner";
import BigModal, { BigModalProps } from "@components/Modals/BigModal";

export interface AboutModalProps extends BigModalProps {
  categoryName: LearnMoreItemCategoryType;
}

const AboutModal: FC<AboutModalProps> = ({ categoryName, ...rest }) => {
  const category = learnMoreCategories[categoryName];
  const title = category?.title || "";
  const description = category?.description || "";

  const { data: learnMoreItems } = useApiGet(
    getWebflowLearnMoreItems,
    { collectionId: collectionIds.learnMore },
    { category: categoryName },
    { revalidateOnFocus: false }
  );

  return (
    <BigModal title={title} {...rest}>
      <p className="text-base text-grey-500 mb-4">{description}</p>
      {learnMoreItems ? (
        learnMoreItems.map((item) => (
          <ToggleCard
            key={item.id}
            icon={item.icon}
            title={item.name}
            excerpt={item.headline}
            content={item.article}
          />
        ))
      ) : (
        <LoadingSpinner />
      )}
    </BigModal>
  );
};

export default AboutModal;
