import { useState } from "react";
import { addDoc, collection, doc, writeBatch } from "firebase/firestore";
import { pull } from "lodash";

import useLabels from "@hooks/use-labels";
import analytics from "@lib/analytics";
import { compatDB as db } from "@lib/firebase-config";

import { useUpdateContactsCache } from "../../../hooks/useUpdateContactsCache";

export const useLabelsSelectorBatch = ({ userId }: { userId: string }) => {
  const [loading, setLoading] = useState(false);
  const [selectorIsOpen, setSelectorIsOpen] = useState(false);
  const [selectorPosition, setSelectorPosition] = useState(undefined);
  const [updating, setUpdating] = useState<string | null>(null);
  const { labels } = useLabels(userId);
  const { updateCache } = useUpdateContactsCache();
  const batch = writeBatch(db);

  const track = (event) => analytics.track({ event, userId });

  const getContact = (contactId) => {
    const contactDocRef = doc(db, `users/${userId}/clients/${contactId}`);
    return {
      contactDocRef,
    };
  };

  const getContactLabels = (contactId, byContact) =>
    byContact.find((contact) => contact.id === contactId).labels;

  const applyChanges = (
    label,
    contactIds,
    byContact,
    operator
  ): { contactId: string; newLabels: string[] }[] => {
    const updates: { contactId: string; newLabels: string[] }[] = [];
    for (let i = 0; i < contactIds.length; i++) {
      const contactId = contactIds[i];
      const contactLabels = getContactLabels(contactId, byContact);
      const { contactDocRef } = getContact(contactId);
      if (operator == "add") {
        if (!contactLabels.includes(label)) {
          batch.update(contactDocRef, { labels: [...contactLabels, label] });
          updates.push({ contactId, newLabels: [...contactLabels, label] });
        }
      } else if (operator == "remove") {
        if (contactLabels.includes(label)) {
          batch.update(contactDocRef, { labels: pull(contactLabels, label) });
          updates.push({ contactId, newLabels: pull(contactLabels, label) });
        }
      }
    }

    track(`client_list_labels_${operator}_batch`);

    return updates;
  };

  const toggleLabel = async ({
    label,
    contactIds,
    selectedLabels,
    byContact,
  }) => {
    setLoading(true);
    setUpdating(label);
    let updates: { contactId: string; newLabels: string[] }[];
    if (selectedLabels.includes(label)) {
      updates = applyChanges(label, contactIds, byContact, "remove");
    } else {
      updates = applyChanges(label, contactIds, byContact, "add");
    }
    await batch.commit();
    updateCache(
      updates.map(({ contactId, newLabels }) => ({
        contactId,
        data: { labels: newLabels },
      }))
    );
    setLoading(false);
    setUpdating(null);
  };

  const createLabel = async ({
    data,
    contactIds,
    selectedLabels,
    byContact,
  }) => {
    const now = new Date();
    const { id: label } = await addDoc(
      collection(db, `users/${userId}/labels`),
      {
        ...data,
        status: "active",
        createdAt: now,
        updatedAt: now,
      }
    );
    toggleLabel({
      label,
      contactIds,
      selectedLabels,
      byContact,
    });
    track("client_list_labels_create_batch");
  };

  return {
    selectorIsOpen,
    setSelectorIsOpen,
    selectorPosition,
    setSelectorPosition,
    labels,
    toggleLabel,
    loading,
    createLabel,
    updating,
  };
};
