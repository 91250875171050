import { FC } from "react";

import CopyLinkButton from "@components/Buttons/CopyLinkButton";
import LinkedIcon from "@components/Icons/LinkedIcon";

interface GetLinkModalProps {
  link: string;
  onCopy: () => void;
  type: string;
  disabled?: boolean;
}

const GetLinkModal: FC<GetLinkModalProps> = ({
  link,
  onCopy,
  type,
  disabled = false,
}) => {
  return (
    <div className="p-4 sm:p-8 flex justify-between items-center">
      <div>
        <div className="flex items-center sm:pb-2">
          <div className="bg-blue-950 rounded-full p-1 mr-4">
            <LinkedIcon />
          </div>
          <div className="text-xl sm:text-3xl">Get link</div>
        </div>
        <div className="hidden sm:block">
          Send this link to your client via your favorite communication channel
        </div>
      </div>
      <CopyLinkButton
        disabled={disabled}
        link={link}
        heapEventName={`shared_${type}_via_copy_link`}
        onCopy={onCopy}
      />
    </div>
  );
};

export default GetLinkModal;
