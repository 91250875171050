export class GenericError extends Error {
  constructor(
    message: string,
    public innerError?: Error,
    public payload?: unknown,
    public code?: string
  ) {
    super(message);
    Error.captureStackTrace(this);
  }
}
