import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const LabelIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4999 10C16.3283 10 16.9999 9.32843 16.9999 8.5C16.9999 7.67158 16.3283 7 15.4999 7C14.6715 7 13.9999 7.67158 13.9999 8.5C13.9999 9.32843 14.6715 10 15.4999 10Z"
        fill="fill-current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7069 3.29341C10.8945 3.10556 11.1491 3 11.4146 3H18.9999C20.1045 3 20.9999 3.89543 20.9999 5V12.5858C20.9999 12.851 20.8946 13.1054 20.707 13.2929L11.7093 22.2906C11.3179 22.682 10.683 22.681 10.2928 22.2883L1.74177 13.6814C1.35361 13.2907 1.35441 12.6597 1.74355 12.27L10.7069 3.29341ZM18.9999 5V12.1716L11.0045 20.167L3.86257 12.9783L11.8292 5H18.9999Z"
      />
    </svg>
  );
};

export default LabelIcon;
