import React, { useEffect, useState } from "react";
import classNames from "classnames";

import CheckIcon from "@components/Icons/CheckIcon";

export const CheckboxComponent = (
  { variant, className, onChange, ...rest },
  ref
) => {
  const [checked, setChecked] = useState(rest.defaultChecked);

  const handleChange = (e) => {
    onChange && onChange(e);
    setChecked(e.target.checked);
  };

  useEffect(() => {
    setChecked(rest.defaultChecked);
  }, [rest.defaultChecked]);

  return (
    <label
      className={classNames(
        "w-6 h-6 border-2 text-foreground/50 border-foreground/50 flex items-center justify-center rounded-sm transition duration-150 ease-in-out cursor-pointer",
        className,
        rest.disabled
          ? `opacity-25`
          : `hover:border-foreground  hover:text-foreground`
      )}
    >
      <input
        ref={ref}
        type="checkbox"
        className="absolute opacity-0 focus:ring-0 z-10"
        onChange={handleChange}
        {...rest}
      />
      <CheckIcon
        className={classNames(
          "transform scale-125",
          checked ? "opacity-100" : "opacity-0"
        )}
      />
    </label>
  );
};

export const Checkbox = React.forwardRef(CheckboxComponent);
