import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const ChatIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.13572 15.7941L6.69528 17.4378L8.37576 16.9875L9.04081 17.356C9.91596 17.8409 10.9228 18.1176 12 18.1176C15.3787 18.1176 18.1176 15.3787 18.1176 12C18.1176 8.62132 15.3787 5.88235 12 5.88235C8.62132 5.88235 5.88235 8.62132 5.88235 12C5.88235 13.1402 6.19251 14.2022 6.73191 15.1126L7.13572 15.7941ZM4.45515 18.5253L5.11247 16.0721C4.40571 14.8793 4 13.487 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C10.5956 20 9.27575 19.6381 8.12856 19.0025L5.60786 19.678C4.90802 19.8655 4.26763 19.2251 4.45515 18.5253Z"
        fill="fill-current"
      />
    </svg>
  );
};

export default ChatIcon;
