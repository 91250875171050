export const handleOutsideClick = (
  ref: React.MutableRefObject<any>,
  setClose: (value: React.SetStateAction<boolean>) => void
): any => {
  const handleClick = (e: MouseEvent) => {
    const exists = ref?.current?.contains(e.target);
    if (!exists) setClose(false);
  };
  document.addEventListener("mousedown", handleClick);
  return () => {
    document.removeEventListener("mousedown", handleClick);
  };
};
