import axios from "axios";

import { GroupType } from "@lib/shared-types";

export const updateGroup = async (
  groupId: string,
  payload: Partial<GroupType>,
  uid: string
): Promise<GroupType> =>
  await axios.put(`/api/v1/users/${uid}/groups/${groupId}`, payload);
