import React, { useRef } from "react";

import useAccessType from "@hooks/use-access-type";
import { useIsMobile } from "@hooks/use-window-size";

import { useLabelsSelector } from "./hooks";
import LabelsList from "./LabelsList";
import LabelsSelector from "./LabelsSelector";
import { getOffsetPosition } from "./utils";

const LabelsAddButton = ({ userId, contact }) => {
  const ref = useRef();
  const {
    selectorIsOpen,
    setSelectorIsOpen,
    selectorPosition,
    setSelectorPosition,
    labels,
    contactLabelsIds,
    toggleLabel,
    createLabel,
    contactLabels,
    updating,
  } = useLabelsSelector({ userId, contact, ref });
  const { hasElevatedAccess } = useAccessType();
  const canEditLabels = hasElevatedAccess;
  const isMobile = useIsMobile();

  const handleClickButton = (e) => {
    setSelectorPosition(
      getOffsetPosition({
        e,
        isMobile,
        buttonWidth: 49,
        buttonHeight: 29,
      })
    );
  };

  const renderButtonAndSelector = (
    <div ref={ref} className="flex relative">
      <button
        onClick={handleClickButton}
        className="h-7 bg-grey-950 text-xs text-grey-500 py-1 px-2 rounded-lg focus:outline-none hover:bg-action-900 transition"
      >
        + label
      </button>
      <LabelsSelector
        isOpen={selectorIsOpen}
        labels={labels}
        labelsSelected={contactLabelsIds}
        onClickLabel={toggleLabel}
        onClickCreate={createLabel}
        onClickBackdrop={setSelectorIsOpen}
        position={selectorPosition}
        className="mt-8"
        updating={updating}
      />
    </div>
  );

  return (
    <div className="flex flex-row items-center flex-1 w-full relative">
      <LabelsList
        labels={contactLabels}
        onClickRemove={toggleLabel}
        componentAfter={canEditLabels && renderButtonAndSelector}
        className="justify-center sm:justify-start"
      />
    </div>
  );
};

export default LabelsAddButton;
