import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import classNames from "classnames";

import { useAuth } from "@contexts/auth";
import useEmailAliases from "@hooks/use-email-aliases";

import Spinner from "@components/LoadingSpinner";

type OptionType = {
  value: string;
  label: string;
};

interface SelectAliasesProps {
  email?: string;
  prependOptions?: OptionType[];
  onChange: (value: string) => void;
  value: string;
  disabled?: boolean;
  className?: string;
}

type SelectAliasesControlledProps = Omit<
  SelectAliasesProps,
  "onChange" | "value"
> & {
  name?: string;
  onChange?: (value: string) => void;
};

const SelectAliases: FC<SelectAliasesProps> = ({
  email,
  onChange,
  value,
  prependOptions = [],
  disabled = false,
  className,
}) => {
  const { uid: coachId } = useAuth();
  const { aliases, isLoading: isLoadingAliases } = useEmailAliases({
    coachId,
    email,
  });
  const aliasesOptions = aliases.map((item) => ({
    value: item.sendAsEmail,
    label: `Via ${item.sendAsEmail}`,
  }));
  const options = [...prependOptions, ...aliasesOptions];

  // @TODO: reuse the Select component when it is merged
  // - PR: https://github.com/practice-do/practice-web/pull/1674
  return isLoadingAliases ? (
    <div className={classNames("flex items-center", className)}>
      <Spinner variant="transparent" width="24" height="24" />
    </div>
  ) : (
    options?.length > 1 && (
      <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        className={classNames(
          "cursor-pointer font-medium bg-grey-950 border-none rounded-lg max-w-[250px] px-4 pr-10 h-12 focus:ring-transparent truncate",
          disabled && "text-grey-800",
          className
        )}
      >
        {options.map((item, index) => (
          <option key={`option-email-alias-${index}`} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    )
  );
};

export const SelectAliasesControlled: FC<SelectAliasesControlledProps> = ({
  name = "fromEmail",
  ...props
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <SelectAliases
          {...props}
          value={value}
          onChange={(changedValue) => {
            onChange(changedValue);
            props?.onChange && props.onChange(changedValue);
          }}
        />
      )}
    />
  );
};

export default SelectAliases;
