import { getContact } from "api-services/definitions/contacts";
import { useApiGet } from "api-services/endpoints";
import _ from "lodash";
import { useDocument } from "swr-firebase";

import { useAuth } from "@contexts/auth";
import { ClientType } from "@lib/data/schemas/client";

type UseContactType = (
  contactId: string | undefined,
  coachUserId?: string
) => {
  contact?: ClientType | null;
  loading: boolean;
};

const useContact: UseContactType = (contactId, coachUserId = undefined) => {
  const { uid } = useAuth();
  const { data } = useDocument<ClientType>(
    contactId ? `users/${coachUserId || uid}/clients/${contactId}` : null,
    { listen: true }
  );

  if (data?.address && _.isString(data.address)) {
    data.address = {
      city: "",
      country: "",
      line1: data.address,
      postalCode: "",
      state: "",
    };
  }

  return { contact: data?.exists ? data : undefined, loading: !data };
};

export default useContact;

type UseUserClientType = (
  userId: string | undefined,
  clientId: string | undefined
) => {
  client?: ClientType | null;
  loading: boolean;
};

/**
 * @deprecated This is bypassing auth checks. Use useOrgContact instead.
 */
export const useUserClient: UseUserClientType = (userId, clientId) => {
  const { data, loading } = useDocument<ClientType>(
    clientId && userId ? `users/${userId}/clients/${clientId}` : null
  );

  return { client: data, loading };
};

export const useOrgContact = (
  oid: string | undefined,
  contactId: string | undefined
) => {
  const {
    data: apiData,
    isLoading,
    error,
  } = useApiGet(
    oid && contactId ? getContact : undefined,
    oid && contactId ? { orgId: oid, contactId } : undefined,
    undefined,
    {
      dedupingInterval: 60000,
    }
  );

  const contact = apiData?.data as ClientType | undefined;

  return {
    isLoading,
    contact,
    error,
  };
};
