import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const LifeHelpIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="fill-current"
        fillRule="evenodd"
        d="M18.329 19.743c.517-.423.99-.897 1.414-1.414A9.959 9.959 0 0022 12a9.959 9.959 0 00-2.257-6.329l-.026-.032a10.062 10.062 0 00-1.388-1.382A9.959 9.959 0 0012 2C6.477 2 2 6.477 2 12a9.96 9.96 0 002.284 6.36A9.959 9.959 0 0012 22a9.959 9.959 0 006.329-2.257zm-.01-2.837A7.965 7.965 0 0020 12c0-1.849-.627-3.55-1.68-4.906l-2.148 2.149c.523.79.828 1.738.828 2.757 0 1.02-.305 1.967-.828 2.757l2.148 2.149zm-1.413 1.414l-2.149-2.148A4.977 4.977 0 0112 17a4.977 4.977 0 01-2.757-.828L7.094 18.32A7.966 7.966 0 0012 20a7.965 7.965 0 004.906-1.68zM5.68 16.906A7.966 7.966 0 014 12c0-1.849.627-3.551 1.68-4.906l2.148 2.149A4.977 4.977 0 007 12c0 1.02.305 1.967.828 2.757L5.68 16.906zm3.563-9.078A4.977 4.977 0 0112 7c1.02 0 1.967.305 2.757.828l2.149-2.148A7.966 7.966 0 0012 4a7.965 7.965 0 00-4.906 1.68l2.149 2.148zM12 15a3 3 0 100-6 3 3 0 000 6z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default LifeHelpIcon;
