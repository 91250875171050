import { FC, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { PackageSchedulerType } from "api-services/definitions/package-instances";
import { capitalize } from "lodash";
import moment from "moment";

import { getNormalizedDate } from "@lib/appointments";
import { AppointmentType } from "@lib/data/schemas/appointment";
import { PackageInstanceType } from "@lib/data/schemas/package-instance";
import { PackageType } from "@lib/data/schemas/packages";
import { getUsageBasedPackageInstanceCycleDates } from "@lib/packages/package-instances";

import { getPackageInstanceInfo } from "@components/PackageInstance/utils";
import { progressText } from "@components/PackageInstances/PackageProgress/utils";
import { OptionType } from "@components/Select/Option";
import FormSelect from "@components/Select/Select/FormSelect";

import CyclePicker from "./CyclePicker";
import { frequencyCopies } from "./utils";

interface AssignPackageFormFieldsProps {
  appointment: AppointmentType;
  packages: PackageType[];
  packageInstances: Array<
    PackageInstanceType & {
      ownerId?: string;
    }
  >;
  packageSchedulers: PackageSchedulerType[];
  packageAppointments?: AppointmentType[];
  isPackageFieldRequired?: boolean;
  isSchedulerFieldRequired?: boolean;
}

export const getActivePackageInstances = (
  packageInstances: PackageInstanceType[],
  packageSchedulers: PackageSchedulerType[]
) => {
  const data = packageInstances?.filter(
    (packageInstance: PackageInstanceType) => {
      const schedulers =
        packageSchedulers?.filter(
          (schedler) => schedler.packageInstanceId === packageInstance.id
        ) ?? [];

      const { isCompleted } = getPackageInstanceInfo(
        packageInstance,
        schedulers,
        true
      );

      return packageInstance.status === "active" && !isCompleted;
    }
  );

  return data ?? [];
};

const AssignPackageSchedulerFormFields: FC<AssignPackageFormFieldsProps> = ({
  appointment,
  packages,
  packageInstances,
  packageSchedulers,
  packageAppointments,
  isPackageFieldRequired = true,
  isSchedulerFieldRequired = true,
}) => {
  const { watch, setValue, register } = useFormContext();
  const packageInstanceId = watch("packageInstanceId");
  const schedulerId = watch("schedulerId");
  const cycle = watch("cycle");
  const filteredPackages = useMemo(() => {
    return getActivePackageInstances(packageInstances, packageSchedulers)
      .map((packageObject: PackageInstanceType) => {
        const schedulers = packageSchedulers?.filter(
          (schedler) => schedler.packageInstanceId === packageObject.id
        );

        const description = progressText(packageObject, schedulers);

        return {
          value: packageObject?.id,
          title: packageObject?.title,
          description,
          icon: packageObject?.icon,
        };
      })
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [packages, packageInstances, packageSchedulers]);

  const filteredSchedulers = useMemo(() => {
    return packageSchedulers?.filter((scheduler) => {
      return scheduler.packageInstanceId === packageInstanceId;
    });
  }, [packageSchedulers, packageInstanceId]);

  const packageInstance = packageInstances.find(
    (packageObject) => packageObject.id === packageInstanceId
  );

  const scheduler = filteredSchedulers.find(
    (scheduler) => scheduler.id === schedulerId
  );

  const isUsageBased = packageInstance?.packageType === "usage";
  const currentCycleDates = getUsageBasedPackageInstanceCycleDates(
    packageInstance!,
    getNormalizedDate(appointment.start),
    packageInstance?.currentCycle ?? 1
  );

  useEffect(() => {
    if (!isUsageBased) return;

    if (cycle !== currentCycleDates?.cycle) {
      setValue("cycle", currentCycleDates?.cycle);
    }
  }, [currentCycleDates, cycle]);

  const shouldShowCycle = !!packageInstance?.frequency && !!scheduler;

  const renderCyclePreview = () => {
    if (!packageInstance) return null;

    const cycleStart = moment(currentCycleDates?.startDate);
    const cycleEnd = moment(currentCycleDates?.endDate);

    if (!currentCycleDates) return null;

    const frequency =
      frequencyCopies[packageInstance.frequency?.type ?? "months"];
    return (
      <div className="text-sm rounded-md border border-grey-900 p-4">
        <span className="font-medium">
          {capitalize(frequency.singular)} {currentCycleDates?.cycle}:
        </span>{" "}
        <span className="text-grey-500">
          {cycleStart.format("MMM DD")} - {cycleEnd.format("MMM DD")}
        </span>
        <input
          {...register("cycle")}
          type="hidden"
          value={currentCycleDates?.cycle}
        />
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <FormSelect
        name="packageInstanceId"
        placeholder="Choose a package"
        simpleSearchPlaceholder="Search for packages..."
        options={filteredPackages as unknown as OptionType[]}
        required={isPackageFieldRequired}
      />
      {packageInstanceId && !!filteredSchedulers?.length && (
        <>
          <FormSelect
            name="schedulerId"
            placeholder="Choose a scheduler"
            simpleSearchPlaceholder="Search for schedulers..."
            options={filteredSchedulers?.map((scheduler) => ({
              value: scheduler.id,
              title: scheduler.title,
              description: `${scheduler.duration} minutes`,
              icon: scheduler.icon,
            }))}
            required={isSchedulerFieldRequired}
          />
          {shouldShowCycle && !isUsageBased && (
            <CyclePicker
              packageInstance={packageInstance}
              appointment={appointment}
              startDate={packageInstance.startDate}
              name="cycle"
              existingAppointments={packageAppointments}
            />
          )}
          {shouldShowCycle && isUsageBased && renderCyclePreview()}
        </>
      )}
    </div>
  );
};

export default AssignPackageSchedulerFormFields;
