import { FC } from "react";

import useCopyToClipboard from "@hooks/use-copy-to-clipboard";
import { getCurrentURIFromServer } from "@lib/utils";

import { Button } from "@components/Button";

interface CopyLinkButtonProps {
  className?: string;
  link: string;
  isHref?: boolean;
  onCopy?: () => void;
  heapEventName?: string;
  disabled?: boolean;
}

const CopyLinkButton: FC<CopyLinkButtonProps> = ({
  className,
  link,
  isHref,
  onCopy,
  heapEventName,
  disabled = false,
}) => {
  const url = isHref ? `${getCurrentURIFromServer()}${link}` : link;
  const { copyToClipboard, isCopyLabelVisible } = useCopyToClipboard();

  return (
    <Button
      className={className}
      disabled={disabled}
      onClick={() => {
        copyToClipboard(url);
        onCopy && onCopy();
      }}
      data-heap-event-name={heapEventName || "link_copied"}
    >
      {isCopyLabelVisible ? "Link copied!" : "Copy link"}
    </Button>
  );
};

export default CopyLinkButton;
