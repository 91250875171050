import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const UnarchiveIcon: FC<SVGIconProps> = ({ className }) => (
  <svg
    className={classNames("fill-current", className)}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fillRule="evenodd"
      d="M4.467 10.005L3.95 8.073l13.523-3.624.518 1.932-13.523 3.624zM1.63 7.142a.5.5 0 01.354-.613l16.42-4.4a.5.5 0 01.613.354l1.294 4.83a.5.5 0 01-.353.612L8.48 11H20v12H4V12.2l-.464.125a.5.5 0 01-.612-.354l-1.295-4.83zM18 13H6v8h12v-8zm-9 1h6v2H9v-2z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default UnarchiveIcon;
