import { FC, ReactNode, useMemo, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import classNames from "classnames";

import { useAuth } from "@contexts/auth";
import useContact from "@hooks/use-contact";
import { useContactSearch } from "@hooks/use-contact-search";
import useDebounce from "@hooks/use-debounce";
import useGroups from "@hooks/use-groups";
import useLabels from "@hooks/use-labels";
import { displayNameFromContact } from "@lib/contacts";
import { ClientType } from "@lib/data/schemas/client";
import { GroupType } from "@lib/shared-types";
import { sortByTitle } from "@lib/sortBy";

import MemberSubtitle from "@components/Groups/MemberSubtitle";

import SelectFormMembers from "./Form/SelectFormMembers";
import { PersonInformation } from "./Contact";
import { ErrorMessage } from "./ErrorMessage";
import { LabelsList } from "./Labels";
import Listbox, { ListboxOption } from "./Listbox";
import { Trigger } from "./Trigger";

interface ClientPickerProps {
  error?: string;
  contactId?: string;
  handleClientSelection: (id: string, client?: ClientType) => void;
  readonlyContact?: boolean;
  label?: string;
  className?: string;
  excludeArchived?: boolean;
  excludedContacts?: string[];
  showGroups?: boolean;
  allowCreateNewClient?: boolean;
  showLabel?: boolean;
  customAddButton?: boolean;
  noLabel?: boolean;
  customListboxClassNames?: string;
  customPlaceholder?: ReactNode;
  wrapperOptionsClassName?: string;
  showBillingEmail?: boolean;
  includeClientOrganizations?: boolean;
}

export const ClientPicker: FC<ClientPickerProps> = ({
  error,
  contactId: contactOrGroupId,
  handleClientSelection,
  readonlyContact = false,
  label,
  className,
  excludeArchived = false,
  excludedContacts,
  showGroups = false,
  allowCreateNewClient = false,
  showLabel = true,
  customAddButton = false,
  noLabel = false,
  customListboxClassNames,
  customPlaceholder,
  wrapperOptionsClassName,
  showBillingEmail = false,
  includeClientOrganizations = false,
}) => {
  const { oid } = useAuth();
  const [searchKey, setSearchKey] = useState("");
  const debouncedSearchTerm = useDebounce(searchKey, 500);
  const clientType = includeClientOrganizations ? undefined : "individual";
  const {
    data: allData,
    loadMore,
    loading,
  } = useContactSearch(oid, "name", {
    searchKey: debouncedSearchTerm,
    clientType,
    excludeArchived,
  });
  const { activeGroups: groups } = useGroups(oid);
  const sortedGroups = sortByTitle(groups);

  const { contact } = useContact(contactOrGroupId);

  const data = useMemo(() => {
    let result: any[] = allData || [];
    if (excludedContacts) {
      result = result.filter((c) => !excludedContacts.includes(c.id));
    }
    if (
      contactOrGroupId &&
      !result.find((c) => c.id === contactOrGroupId) &&
      contact
    ) {
      result = [contact, ...result];
    }
    return result;
  }, [allData, contact, contactOrGroupId, excludedContacts]);

  const selectedContact = data?.find((c) => c.id === contactOrGroupId);
  const selectedGroup = groups?.find(
    (g: GroupType) => g.id === contactOrGroupId
  );

  const placeholder = customPlaceholder ? (
    customPlaceholder
  ) : contactOrGroupId === "NEW_CONTACT" ? (
    "New client"
  ) : contactOrGroupId ? (
    selectedContact ? (
      <PersonInformation person={selectedContact} />
    ) : (
      selectedGroup?.title
    )
  ) : (
    `Pick a client ${showGroups ? "or group" : ""}`
  );

  return (
    <div className={classNames(className, "relative")} id="client-picker">
      <Listbox
        label={label || "Client"}
        onChange={(value) =>
          handleClientSelection(
            value,
            data.find((c) => c.id === value)
          )
        }
        placeholder={placeholder}
        readonly={readonlyContact}
        value={contactOrGroupId}
        error={error && !contactOrGroupId}
        showLabel={showLabel}
        customAddButton={customAddButton}
        noLabel={noLabel}
        customListboxClassNames={customListboxClassNames}
        wrapperOptionsClassName={wrapperOptionsClassName}
        customMenuWrapperClassNames="bg-white"
        searchProps={{
          placeholder: "Search for clients...",
          onChange: setSearchKey,
          name: "search-client",
          value: searchKey,
        }}
      >
        {allowCreateNewClient && (
          <ListboxOption
            primaryText="New client"
            secondaryText="Create a new client"
            value="NEW_CONTACT"
          />
        )}
        {showGroups &&
          sortedGroups?.map((group: GroupType) => (
            <ListboxOption
              key={group.id}
              primaryText={group.title}
              secondaryText={<MemberSubtitle members={group.members} />}
              value={group.id}
            />
          ))}
        {data?.map((contact) => (
          <ListboxOption
            key={contact.id}
            primaryText={displayNameFromContact(contact)}
            secondaryText={
              (showBillingEmail && contact?.address?.email) || contact?.email
            } // show billing email if it exists
            value={contact.id}
          />
        ))}
        {data?.length > 0 && (
          <Trigger isLoading={loading} onIntersection={loadMore} />
        )}
      </Listbox>
      {error && !contactOrGroupId && (
        <ErrorMessage className="mt-2">{error}</ErrorMessage>
      )}
    </div>
  );
};

export const FormClientPicker = (
  props: Omit<ClientPickerProps, "handleClientSelection"> & {
    name: string;
    control: any;
    onValueChange?: (value: string, client: ClientType) => void;
  }
) => {
  const { name, onValueChange, ...rest } = props;
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name, control: props.control });

  return (
    <ClientPicker
      contactId={value}
      handleClientSelection={(value, client) => {
        onValueChange?.(value, client);
        onChange(value);
      }}
      error={error?.message}
      {...rest}
    />
  );
};

const ClientDetails = ({
  labelsIds,
  index,
}: {
  labelsIds?: string[];
  index: number;
}) => {
  const {
    oid,
    organizationAccounts: { accounts: members },
  } = useAuth();
  const { labels } = useLabels(oid);
  const {
    control,
    formState: { errors },
  } = useFormContext();

  if (!labels) return null;
  const selectedLabels = labels?.filter((l) => labelsIds?.includes(l.id));
  return (
    <div className="ml-14">
      {selectedLabels?.length ? (
        <LabelsList className="my-2" labels={selectedLabels} />
      ) : null}
      <SelectFormMembers
        control={control}
        errors={errors}
        members={members}
        label={"Assigned member"}
        name={`members.${index}.assigneeId`}
      />
    </div>
  );
};

export const ClientListItem: FC<{
  field: string;
  index: number;
  showLabels?: boolean;
}> = ({ field, index, showLabels }) => {
  const { contact } = useContact(field.clientId);

  if (!contact) return null;
  return (
    <div>
      <div className="my-2">
        <PersonInformation person={contact!} />
      </div>
      {showLabels && <ClientDetails labelsIds={contact.labels} index={index} />}
    </div>
  );
};

export default ClientPicker;
