import classNames from "classnames";

import { ParentOptionType } from "@components/Form/SelectForm/types";
import ChevronLeftIcon from "@components/Icons/ChevronLeftIcon";

interface ParentOptionProps {
  parent: ParentOptionType;
  wrapperClassNames?: string;
}

const ParentOption: React.FC<ParentOptionProps> = ({
  parent,
  wrapperClassNames,
}) => (
  <div
    className={classNames(
      "flex justify-between px-3 items-center hover:cursor-pointer",
      wrapperClassNames
    )}
  >
    <div className="flex gap-3 items-center">
      {parent?.icon && <parent.icon />}
      <div>
        <h2 className="font-medium text-sm">{parent.title}</h2>
        <p className="text-xs text-grey-500 pt-0.5">{parent.subtitle}</p>
      </div>
    </div>
    {parent?.rightIcon ? (
      <parent.rightIcon />
    ) : (
      <ChevronLeftIcon className="-scale-x-1 h-4 w-4" />
    )}
  </div>
);

export default ParentOption;
