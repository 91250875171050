import { useRef, useState } from "react";
import classNames from "classnames";

import MoreIcon from "@components/Icons/MoreIcon";
import Transition from "@components/Transition";

import SecondaryDropdown, {
  DropdownItem,
  SecondaryDropdownProps,
} from "./Dropdowns/SecondaryDropdown";

type Size = "small" | "regular";
type SmallDropdownProps = Partial<SecondaryDropdownProps> & { size?: Size };
type BaseProps = {
  className?: string;
  color?: string;
};
type IconActionProps = BaseProps &
  (
    | {
        type: "action";
        onClick: () => void;
        Icon: React.ElementType;
        text: string;
        dropdownItems?: never;
        showOnHover?: boolean;
        size?: Size;
      }
    | {
        type: "dropdown";
        dropdownItems: DropdownItem[];
        Icon?: never;
        onClick?: never;
        text?: never;
        showOnHover?: boolean;
        size?: Size;
      }
  );

const SmallDropdown = ({
  show,
  setShow,
  dropdownItems,
  size = "regular",
}: SmallDropdownProps) => {
  const optionsRef = useRef(null);

  return (
    <div
      className="relative w-full h-full flex items-center justify-center"
      ref={optionsRef}
    >
      <MoreIcon />
      <SecondaryDropdown
        show={show}
        setShow={setShow}
        onMouseLeave={() => setShow?.(false)}
        className={size === "small" ? "" : "mt-14"}
        dropdownItems={dropdownItems}
        buttonRef={optionsRef}
      />
    </div>
  );
};

const IconAction = ({
  Icon,
  type = "action",
  text,
  onClick,
  className,
  color = "action",
  dropdownItems,
  showOnHover = true,
  size = "regular",
}: IconActionProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);

  const onClickDropdown = (e: any) => {
    e.preventDefault();
    setShowDropdown(true);
  };

  const hoverClasses =
    showOnHover &&
    `hidden can-hover:group-hover:flex bg-white hover:bg-${color}-600 hover:text-white`;

  return (
    <div
      onClick={type === "dropdown" ? onClickDropdown : onClick}
      onMouseEnter={() => setShowToolTip(true)}
      onMouseLeave={() => setShowToolTip(false)}
      className={classNames(
        className,
        `absolute focus:outline-none rounded-lg text-black-ink`,
        size === "small" ? "w-8 h-8" : "w-12 h-12",
        hoverClasses
      )}
    >
      {type === "action" ? (
        <div className="relative group">
          <div className="text-action-500 absolute font-semibold top-3 right-14">
            <Transition show={showToolTip}>{text}</Transition>
          </div>
          <Icon className="m-3.5 w-5 h-5" />
        </div>
      ) : type === "dropdown" ? (
        <SmallDropdown
          show={showDropdown}
          setShow={setShowDropdown}
          dropdownItems={dropdownItems}
          size={size}
        />
      ) : null}
    </div>
  );
};

export default IconAction;
