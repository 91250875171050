import axios from "axios";
import useSWR from "swr";

export interface EmailAliasType {
  sendAsEmail: string;
  displayName?: string;
  replyToAddress?: string;
  isPrimary: boolean;
  isDefault: boolean;
}
export type GetAliasesType = (email: string) => Promise<EmailAliasType[]>;

type UseEmailAliasesType = (props: { coachId: string; email?: string }) => {
  isLoading: boolean;
  aliases: EmailAliasType[];
  error?: string;
};

const useEmailAliases: UseEmailAliasesType = ({ coachId, email }) => {
  const url = `/api/v1/gmail/${coachId}/aliases`;
  const args = {
    email,
  };
  const { data, error } = useSWR(
    email ? url : null,
    (url) => axios.post(url, args).then((res) => res.data),
    { dedupingInterval: 60000 }
  );

  return {
    isLoading: email ? !data && !error : false,
    aliases: data || [],
    error,
  };
};

export default useEmailAliases;
