import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import classNames from "classnames";

import { AppointmentType } from "@lib/data/schemas/appointment";
import { PackageInstanceType } from "@lib/data/schemas/package-instance";
import { PackageType } from "@lib/data/schemas/packages";
import { getCycleDates } from "@lib/models/package-instances/calculate-cycles";

import SearchableMultiSelectForm from "@components/Form/SelectForm/SearchableMultiSelectForm";
import { useSelectableListContext } from "@components/SelectableList";

interface CyclePickerProps {
  packageInstance: PackageInstanceType | PackageType;
  appointment: AppointmentType;
  startDate?: string | Date;
  hideLabel?: boolean;
  onChange?: ({ value }: { value: string }) => void;
  existingAppointments?: AppointmentType[];
  name?: string;
}

const CyclePicker: FC<CyclePickerProps> = ({
  packageInstance,
  appointment,
  startDate,
  onChange,
  name,
  existingAppointments,
}) => {
  const fieldName = name ?? `${appointment.id}-cycle`;
  const cycleDates = getCycleDates(
    packageInstance,
    startDate as string,
    existingAppointments,
    appointment
  );
  const cycleOptions = cycleDates.map((cycleDate) => {
    const isFull = cycleDate.full;
    return {
      value: cycleDate.cycle,
      label: (
        <div
          className={classNames(
            isFull && "cursor-not-allowed",
            "flex items-center gap-4"
          )}
        >
          <div
            className={classNames(
              isFull && "opacity-50",
              "flex gap-1 items-center text-sm truncate"
            )}
          >
            <span>{cycleDate.cycleLabel}:</span>
            <span className="text-grey-500 truncate">{cycleDate.dates}</span>
          </div>
          {isFull && (
            <span className="bg-peach-950 rounded-lg py-0.5 px-1.5 text-peach-500 text-xs flex-none">
              Fully booked
            </span>
          )}
        </div>
      ),
      disabled: isFull,
      isCurrent: cycleDate.isCurrent,
      start: cycleDate.start,
      end: cycleDate.end,
    };
  });

  const { setValue, watch } = useFormContext();

  const { selectedIds } = useSelectableListContext();

  const value = watch(fieldName);

  const shouldPickCycle = selectedIds.includes(appointment.id) || !!name;

  const getBestCycle = () => {
    const first = cycleOptions[0];
    const last = cycleOptions.at(-1)!;
    if (appointment.start < first.start) {
      return first.value;
    } else if (appointment.start > last.end) {
      return last.value;
    }
    for (const cycle of cycleOptions) {
      if (appointment.start > cycle.start && appointment.start < cycle.end) {
        return cycle.value;
      }
    }
  };

  useEffect(() => {
    if (shouldPickCycle && !value) {
      const cycle = getBestCycle();
      setValue(fieldName, getBestCycle(), {
        shouldDirty: true,
        shouldValidate: true,
      });
      onChange?.({ value: String(cycle!) });
    } else if (!shouldPickCycle && value) {
      setValue(fieldName, null, { shouldValidate: true, shouldDirty: true });
      onChange?.({ value: "" });
    }
  }, [shouldPickCycle]);

  const orderedCycleOptions = cycleOptions.sort((x, y) => {
    return x.isCurrent ? -1 : y.isCurrent ? 1 : 0;
  });

  if (!cycleOptions.length) return null;

  return (
    <SearchableMultiSelectForm
      options={orderedCycleOptions}
      name={fieldName}
      placeholder="Choose a cycle"
      type="cycles"
      controlClassNames="!bg-white !py-[7px]"
      menuClassNames="!pb-2"
      emptyMessage="No cycles found"
      onInputChange={onChange}
      defaultValue={orderedCycleOptions[0]}
    />
  );
};

export default CyclePicker;
