type WindowPopupType = (props: {
  url: string;
  name: string;
  width?: number;
  height?: number;
}) => void;

let windowObjectReference: any = null;
let previousWindowUrl: string = null;

const windowPopup: WindowPopupType = ({
  url,
  name,
  width = 600,
  height = 700,
}) => {
  const windowTop = window.screen.height / 2 - height / 2;
  const windowLeft = window.screen.width / 2 - width / 2;

  const windowSettings = [
    "toolbar=no",
    "menubar=no",
    `width=${width}`,
    `height=${height}`,
    `top=${windowTop}`,
    `left=${windowLeft}`,
  ].join(",");

  if (windowObjectReference === null || windowObjectReference.closed) {
    windowObjectReference = window.open(url, name, windowSettings);
  } else if (previousWindowUrl !== null) {
    windowObjectReference = window.open(url, name, windowSettings);
    windowObjectReference.focus();
  } else {
    windowObjectReference.focus();
  }
  previousWindowUrl = url;
};

export default windowPopup;
