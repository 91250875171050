import { ArtifactKeyTypes, IconType } from "@lib/shared-types";

import CalendarIcon from "@components/Icons/CalendarIcon";
import CreditCardIcon from "@components/Icons/CreditCardIcon";
import EventsIcon from "@components/Icons/EventsIcon";
import FolderIcon from "@components/Icons/FolderIcon";
import FormIcon from "@components/Icons/FormIcon";
import NoteIcon from "@components/Icons/NoteIcon";

import { getCoachBaseUrl } from "../../utils";

export const getArtifactLink = (
  user: Record<string, any> | string,
  artifact: Record<string, any> | string,
  url: string,
  queryString?: any
): string => {
  if (!artifact || !user) return "";
  const baseUrl = getCoachBaseUrl(user);
  const artifactId =
    typeof artifact === "string" ? artifact : artifact.slug || artifact.id;

  const qs = queryString ? `?${new URLSearchParams(queryString)}` : "";

  return `${baseUrl}/${url}/${artifactId}${qs}`;
};

const libraryData = {
  activity: "library",
  cardIcon: FolderIcon,
  cardFooter: "files & links",
};

const recurringAppointmentData = {
  description: "Recurring appointment",
  activity: "appointments",
  cardIcon: CalendarIcon,
};

const eventData = {
  description: "Event",
  activity: "appointments",
  cardIcon: EventsIcon,
};

type ArtifactsDataUtilsType = {
  [key in ArtifactKeyTypes]: {
    description: string;
    activity: string;
    cardIcon: IconType;
    cardFooter?: string;
  };
};

export const artifactsDataUtils: ArtifactsDataUtilsType = {
  appointments: {
    description: "One-time appointment",
    activity: "appointments",
    cardIcon: CalendarIcon,
  },
  forms: {
    description: "Form",
    activity: "forms",
    cardIcon: FormIcon,
  },
  files: { description: "File", ...libraryData },
  links: { description: "Link", ...libraryData },
  folders: { description: "Folder", ...libraryData },
  payments: {
    description: "Invoice",
    activity: "invoices",
    cardIcon: CreditCardIcon,
  },
  notes: {
    description: "Note",
    activity: "notes",
    cardIcon: NoteIcon,
  },
};

export const getArtifactDescription = (artifact: any) => {
  if (artifact?.__type === "appointments") {
    if (artifact?.eventData) return eventData?.description;
    if (artifact?.gcal?.event?.recurringEventId)
      return recurringAppointmentData?.description;
  }
  return artifactsDataUtils[artifact?.__type]?.description;
};

export const getArtifactDescriptionIcon = (artifact: any) => {
  if (artifact?.__type === "appointments") {
    if (artifact?.eventData) return eventData?.cardIcon;
    if (artifact?.gcal?.event?.recurringEventId)
      return recurringAppointmentData?.cardIcon;
  }
  return artifactsDataUtils[artifact?.__type]?.cardIcon;
};
