import React, { Dispatch, SetStateAction, useEffect } from "react";
import { FormProvider, Mode, useForm } from "react-hook-form";

import SeparatorForm from "./Form/SeparatorForm";
import { Modal, ModalContent, ModalTitle } from "./Modal";
import ModalHeaderForm from "./ModalHeaderForm";

interface ModalWrappedFormProps {
  defaultValues?: any;
  onSubmit: (data: any) => Promise<void>;
  onSecondarySubmit?: (data: any) => Promise<void>;
  children: React.ReactNode;
  isLoading?: boolean;
  isSecondaryLoading?: boolean;
  mode?: Mode;
  show?: boolean;
  toggleShow: Dispatch<SetStateAction<boolean>>;
  maxWidth?: string;
  title: string;
  description?: string;
  actionTitle?: string;
  secondaryActionTitle?: string;
  hideHeaderSeperator?: boolean;
  onSubmitHeapEventName?: string;
  onSecondarySubmitHeapEventName?: string;
  modalProps?: any;
  modalContentProps?: any;
  allowNoChange?: boolean;
  actionButtonsPosition?: "top" | "bottom";
  bottomChildren?: React.ReactNode;
  headerLeftContent?: React.ReactNode;
}

const ModalWrappedForm: React.FC<ModalWrappedFormProps> = ({
  defaultValues,
  onSubmit,
  onSecondarySubmit,
  children,
  isLoading,
  isSecondaryLoading,
  mode,
  show,
  toggleShow,
  maxWidth,
  title,
  description,
  actionTitle,
  secondaryActionTitle,
  hideHeaderSeperator,
  onSubmitHeapEventName,
  onSecondarySubmitHeapEventName,
  modalProps = {},
  modalContentProps = {},
  allowNoChange = false,
  actionButtonsPosition = "top",
  bottomChildren,
  headerLeftContent,
}) => {
  /* TODO Wrap this component into <form></form>, and make handleSubmit work properly so that
  isSubmitting is available. Button in HeaderForm, should only have type="submit" */
  const methods = useForm({
    defaultValues: defaultValues,
    mode: mode,
    shouldUnregister: false,
  });

  const isActionButtonsPositionTop = actionButtonsPosition === "top";
  const isActionButtonsPositionBotton = actionButtonsPosition === "bottom";
  const isActionsDisabled = allowNoChange ? false : !methods.formState.isValid;

  useEffect(() => {
    if (show) methods.reset();
  }, [show]);

  const renderHeader = ({
    showPrimaryButton = true,
    showSecondaryButton = true,
    showTitle = true,
    leftContent = undefined,
  }) => (
    <ModalHeaderForm
      title={showTitle ? title : undefined}
      description={description}
      actionTitle={actionTitle}
      onAction={showPrimaryButton ? methods.handleSubmit(onSubmit) : undefined}
      leftContent={leftContent}
      isLoading={isLoading}
      isSecondaryLoading={isSecondaryLoading}
      errors={methods.errors}
      showActionOnMobile={true}
      isDisabled={isActionsDisabled}
      heapEventName={onSubmitHeapEventName}
      secondaryActionTitle={secondaryActionTitle}
      onSecondaryAction={
        showSecondaryButton &&
        onSecondarySubmit &&
        methods.handleSubmit(onSecondarySubmit)
      }
      secondaryHeapEventName={onSecondarySubmitHeapEventName}
    />
  );

  return (
    <Modal
      show={show}
      toggleShow={toggleShow}
      maxWidth={maxWidth}
      {...modalProps}
    >
      <>
        <ModalTitle>
          <div className="-mt-2">
            {renderHeader({
              showPrimaryButton: isActionButtonsPositionTop,
              showSecondaryButton: isActionButtonsPositionTop,
              leftContent: headerLeftContent,
            })}
          </div>
          {!hideHeaderSeperator && <SeparatorForm className="mt-6" />}
        </ModalTitle>
        <FormProvider {...methods}>
          <ModalContent {...modalContentProps}>{children}</ModalContent>
          {isActionButtonsPositionBotton && (
            <div className="mt-4">
              {renderHeader({
                showPrimaryButton: true,
                showSecondaryButton: true,
                showTitle: false,
                leftContent: bottomChildren,
              })}
            </div>
          )}
        </FormProvider>
      </>
    </Modal>
  );
};

export default ModalWrappedForm;
