import { orderBy, uniqWith } from "lodash";
import moment, { Moment } from "moment";
import { TodoType } from "types/db/todos";

import { COMMON_SORT_OPTIONS, sortByDisplayName } from "@lib/sortBy";

// This import isn't great because it lives in @components. We might want to move it
import { SortByItemType } from "@components/SortDropdown";

type OrderedTodosType = (props: {
  todos: TodoType[];
  taskIds: string[];
}) => TodoType[];

export const orderedTodos: OrderedTodosType = ({
  todos = [],
  taskIds = [],
}) => {
  if (!todos?.length) return [];

  // this logic was added as a fallback to keep the functionality working
  // in case of any taskIds is set. It will render the list in the original
  // order
  if (!taskIds?.length) return todos;
  return todos.sort((a, b) => {
    const aIndex = taskIds.indexOf(a.id);
    const bIndex = taskIds.indexOf(b.id);

    // indexOf returns -1 for todos that are not in todoIds
    // for example new todos are not in todoIds initially
    // the -1 messes up the sort and shows them at the top of the list instead of at the bottom
    // we want to show the todos that are not in todoIds at the end of the list
    if (bIndex < 0) return -1;
    if (aIndex < 0) return 1;
    return aIndex - bIndex;
  });
};

type FilteredTodosType = (props: {
  todos: TodoType[];
  todoId: string;
}) => TodoType[];

export const filteredTodos: FilteredTodosType = ({ todos, todoId }) =>
  todos.filter((todo) => todo?.id !== todoId);

type FilteredTaskIdsType = (props: {
  taskIds: string[];
  todoId: string;
}) => string[];

export const filteredTaskIds: FilteredTaskIdsType = ({ taskIds, todoId }) =>
  taskIds.filter((id) => id !== todoId);

export const getDateInfo = (date: Moment) => {
  const now = moment();
  const isToday = now.isSame(date, "day");
  const dateIsFuture = now.isBefore(date);
  const duration = moment.duration(now.diff(date));
  const tomorrow = now.clone().add(1, "days");
  const yesterday = now.clone().subtract(1, "days");
  const isTomorrow = tomorrow.isSame(date, "day");
  const isYesterday = yesterday.isSame(date, "day");

  return {
    isToday,
    dateIsFuture,
    duration,
    isTomorrow,
    isYesterday,
  };
};

export const getDueDateStatus = (date: Moment) => {
  const { isToday, dateIsFuture, isTomorrow } = getDateInfo(date);

  return isToday
    ? "today"
    : isTomorrow
    ? "tomorrow"
    : dateIsFuture
    ? "default"
    : "overdue";
};

export const getHumanizedDate = (date: Moment): string => {
  const { isToday, dateIsFuture, duration, isTomorrow, isYesterday } =
    getDateInfo(date);
  const humanDuration = duration.humanize();
  const when = isToday
    ? "Today"
    : isTomorrow
    ? "Tomorrow"
    : isYesterday
    ? "Yesterday"
    : duration.abs().asDays() > 3
    ? date.format("ddd, MMM D")
    : dateIsFuture
    ? `In ${humanDuration}`
    : `${humanDuration} ago`;

  return when;
};

export const momentDate = (date?: any): Moment => {
  if (moment.isMoment(date)) return date;
  if (date?._seconds) return moment.unix(date._seconds);
  if (date?.seconds) return moment.unix(date.seconds);
  return moment(date);
};

export const todoFilters = [
  {
    text: "Last created",
    value: "last-created",
    transform: (items: TodoType[]) =>
      orderBy(items, (item) => item.createdAt._seconds, ["desc"]),
  },
  COMMON_SORT_OPTIONS.find((c) => c.value === "a-z"),
  {
    text: "Due date",
    value: "due-date",
    transform: (items: TodoType[]) => {
      return orderBy(
        items,
        (item) => {
          return item.dueAt && momentDate(item.dueAt);
        },
        ["asc"]
      );
    },
  },
] as SortByItemType<TodoType>[];

export const filterTodos = (
  todos: TodoType[],
  assigneeId?: string,
  selectedSort?: string
) => {
  const filterAssignee = (todos: TodoType[]) => {
    if (!assigneeId || assigneeId === "all") return todos;
    return todos?.filter(
      (todo: TodoType) => todo.assigneeId === assigneeId || todo.title === ""
    );
  };

  const sortTodos = (todos: TodoType[]) => {
    if (!selectedSort) return todos;
    return todoFilters.find((c) => c.value === selectedSort)?.transform(todos);
  };
  return sortTodos(filterAssignee(todos));
};

export const sortTodoAssignees = (assignees: any[]) => {
  const uniqueAssignees = uniqWith(
    assignees,
    (arrVal, othVal) => arrVal.id === othVal.id
  );
  return [
    ...uniqueAssignees.filter((a) => a.coachUserId),
    ...sortByDisplayName(uniqueAssignees).filter((a) => !a.coachUserId),
  ];
};
