import { FC, ReactNode } from "react";

import { Button } from "./Button";

interface Props {
  title?: string;
  description?: string;
  actionTitle?: string;
  secondaryActionTitle?: string;
  onSecondaryAction?: (value: any) => void;
  showActionOnMobile?: boolean;
  onAction?: (value: any) => void;
  heapEventName?: string;
  isLoading?: boolean;
  isSecondaryLoading?: boolean;
  isDisabled: boolean;
  secondaryHeapEventName?: string;
  leftContent?: ReactNode;
}

const ModalHeaderForm: FC<Props> = ({
  title,
  description,
  actionTitle,
  secondaryActionTitle,
  onSecondaryAction,
  showActionOnMobile = false,
  onAction,
  heapEventName,
  isLoading,
  isSecondaryLoading,
  isDisabled = false,
  secondaryHeapEventName,
  leftContent,
}) => {
  return (
    <div className="bg-white">
      <div className="-ml-4 mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
        {leftContent}
        <div className="ml-4 flex-1">
          {title && (
            <h1 className="text-2xl font-light text-black-ink">{title}</h1>
          )}
          <p className="mt-1 max-w-2xl leading-6 text-grey-500">
            {description}
          </p>
        </div>
        <div className="ml-4 shrink-0">
          <span
            className={`rounded-md ${
              showActionOnMobile ? "inline-flex" : "hidden"
            } sm:inline-flex`}
          >
            {onSecondaryAction && (
              <Button
                isLoading={isSecondaryLoading}
                disabled={isDisabled || isLoading || isSecondaryLoading}
                data-heap-event-name={secondaryHeapEventName}
                onClick={onSecondaryAction}
                className="mr-4"
              >
                {secondaryActionTitle}
              </Button>
            )}
            {onAction && (
              <Button
                primary
                isLoading={isLoading}
                disabled={isDisabled || isSecondaryLoading || isLoading}
                data-heap-event-name={heapEventName}
                onClick={onAction}
              >
                {actionTitle}
              </Button>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ModalHeaderForm;
