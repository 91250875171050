import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import axios, { AxiosResponse } from "axios";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";
import { useRouterQuery } from "@hooks/use-router-query";
import useSnackbar from "@hooks/use-snackbar";

import TextFieldForm from "@components/Form/TextFieldForm";
import AlertIcon from "@components/Icons/AlertIcon";
import ModalWrappedForm from "@components/ModalWrappedForm";

interface Link {
  title?: string;
  link: string;
  url?: string;
}
interface LinkFormModalProps {
  show: boolean;
  toggleShow: (val: boolean) => void;
  link?: any;
  isUserFile: boolean; // File that's stored in user collection rather than contacts (if true)
  contactId?: string;
  heapEventName?: string;
  libraryPath?: string;
}

interface LinkFormProps {
  url?: string;
  title?: string;
}

const LinkForm = ({ url, title }: LinkFormProps) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  //resets LinkForm values upon link submission
  useEffect(() => {
    setValue("url", url ?? "");
    setValue("title", title ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, title]);

  return (
    <>
      <TextFieldForm
        containerClassName="mb-4"
        label="Link title"
        name="title"
        placeholder="Optional"
        register={register}
        errors={errors}
      />
      <TextFieldForm
        label="Link URL"
        name="url"
        placeholder="Add a URL, https:// ..."
        register={register}
        errors={errors}
        errorMessage="Link URL is required"
        required
      />
    </>
  );
};

const LinkFormModal = ({
  show,
  toggleShow,
  link,
  isUserFile = false,
  contactId,
  heapEventName,
  libraryPath,
}: LinkFormModalProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const { params } = useRouterQuery();
  const router = useRouter();
  const snackbar = useSnackbar();
  const { oid } = useAuth();

  useEffect(() => {
    // @ts-expect-error: useRouterQuery is incomplete
    if (params?.addLink) {
      toggleShow(true);
      router.replace(window.location.pathname);
    }
    // @ts-expect-error: useRouterQuery is incomplete
  }, [params?.addLink, toggleShow, router]);

  const generateApiEndpoint = () => {
    if (isUserFile) {
      return `/api/v1/users/${oid}/createLink${
        link ? `?linkId=${link.id}` : ""
      }`;
    } else {
      return `/api/v1/users/${oid}/clients/${contactId}/links${
        link ? `?linkId=${link.id}` : ""
      }`;
    }
  };

  const performRequest = async (data: any): Promise<AxiosResponse<any>> => {
    const apiEndpoint = generateApiEndpoint();

    if (link) {
      return await axios.put(apiEndpoint, {
        ...data,
      });
    } else {
      return await axios.post(apiEndpoint, {
        ...data,
        ...(libraryPath && { path: libraryPath }),
        status: "private",
        coachUserId: oid,
      });
    }
  };

  const onSubmit = async (data: Link) => {
    setIsLoading(true);

    try {
      const result = await performRequest(data);

      if (result.data.error) {
        setIsLoading(false);
        setError(result.data.error);
        return;
      }

      toggleShow(false);
      snackbar.showMessage("Link added");
      //if http success, still need to set isLoading to false
      setError("");
      setIsLoading(false);
      return result;
    } catch (error) {
      const errorMessage =
        error?.response.data.errorMessage || "This is not a valid URL.";
      setError(errorMessage);
    }
    //if http fails, still need to setIsLoading to false
    setIsLoading(false);
  };

  return (
    // @ts-expect-error: thinks all props are mandatory
    <ModalWrappedForm
      show={show}
      onSubmit={onSubmit}
      toggleShow={() => toggleShow(false)}
      maxWidth="max-w-sm"
      title={link ? "Edit link" : "Add a link"}
      actionTitle={link ? "Save" : "Add"}
      hideHeaderSeperator={true}
      defaultValues={{ title: "", link: "" }}
      mode="onChange"
      isLoading={isLoading}
      onSubmitHeapEventName={heapEventName}
    >
      <div className="mt-6">
        <LinkForm url={link?.url} title={link?.title} />
        {error && (
          <div className="mt-2 p-3 text-sm rounded-md text-peach-600 bg-peach-950 flex flex-row items-center w-full">
            <AlertIcon className="block mr-2" />
            <p id="error">{error}</p>
          </div>
        )}
      </div>
    </ModalWrappedForm>
  );
};

export default LinkFormModal;
