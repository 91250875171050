import { useCollection } from "swr-firebase";

import { useAuth } from "@contexts/auth";
import { LabelType } from "@lib/shared-types";

export default function useLabels(organizationId?: string) {
  const { oid } = useAuth();
  const orgId = organizationId ?? oid;
  const { data: labels = [], add } = useCollection<LabelType>(
    orgId ? `users/${orgId}/labels` : null,
    {
      where: ["status", "==", "active"],
      listen: true,
    }
  );

  return {
    labels,
    loading: !labels,
    add,
  };
}
