import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

import Select, { SelectProps } from "./Select";

interface FormSelectProps extends Omit<SelectProps, "value" | "onChange"> {
  name: string;
  onValueChange?: (value: any) => void;
  required?: boolean;
}

const FormSelect: FC<FormSelectProps> = ({
  name,
  onValueChange,
  required = false,
  ...rest
}) => {
  const formMethods = useFormContext();
  const { control } = formMethods;

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { value, onChange } }) => (
        <Select
          value={value}
          onChange={(value) => {
            onChange(value);
            onValueChange?.(value);
          }}
          clearable={!required}
          {...rest}
        />
      )}
    />
  );
};

export default FormSelect;
