import React, { ComponentPropsWithoutRef, FC } from "react";
import classNames from "classnames";

interface DashedButtonProps extends ComponentPropsWithoutRef<"button"> {
  isFull?: boolean;
}

const DashedButton: FC<DashedButtonProps> = ({
  className,
  isFull = false,
  ...props
}) => (
  <button
    className={classNames(
      "border border-dashed border-grey-900 hover:border-grey-800 text-black-ink focus:outline-none",
      "font-medium py-2 px-4 rounded-lg",
      isFull && "w-full",
      className
    )}
    {...props}
  />
);

export default DashedButton;
