import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="evenodd"
        fillRule="evenodd"
        d="M9.457 10.093l2.614-2.615 2.614 2.615a.5.5 0 00.707 0l.568-.568a.5.5 0 000-.707l-3.535-3.536a.5.5 0 00-.708 0L8.182 8.818a.5.5 0 000 .707l.568.568a.5.5 0 00.707 0zm5.228 3.814l-2.614 2.615-2.614-2.615a.5.5 0 00-.707 0l-.568.568a.5.5 0 000 .707l3.535 3.536a.5.5 0 00.708 0l3.535-3.536a.5.5 0 000-.707l-.567-.568a.5.5 0 00-.708 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Icon;
