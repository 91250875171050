import { useMemo } from "react";
import { useRouter } from "next/router";

export function useRouterQuery() {
  const router = useRouter();

  const query = useMemo(() => {
    const query = decode(router.asPath.split("?", 2)[1]);
    return query;
  }, [router.asPath]);

  // does not work yet - would be a nice to have
  // this function is a starting point to centrall control params in a url
  // const changeParams = useCallback((key, value) => {
  //   console.log(key, value);
  //   let newQuery = query;
  //   newQuery[key] = value;
  //   // need to go based off of window.location, etc
  //   router.replace(`${window.location.origin + window.location.pathname}?${encode(newQuery) || ''}`)
  // }, [router, query])

  return { params: query || {} };
}

/*
 * Based on the code of https://github.com/lukeed/qss
 */
const decodeString = (str) => decodeURIComponent(str.replace(/\+/g, "%20"));

function decode(str) {
  if (!str) return {};

  const out = {};

  for (const current of str.split("&")) {
    let [key, value = ""] = current.split("=");
    key = decodeString(key);
    value = decodeString(value);

    if (key.length === 0) continue;

    if (key in out) {
      out[key] = [].concat(out[key], value);
    } else {
      out[key] = value;
    }
  }

  return out;
}
