import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 6.50003C8 6.22389 8.22386 6.00003 8.5 6.00003H20.5C20.7761 6.00003 21 6.22389 21 6.50003V7.50003C21 7.77617 20.7761 8.00003 20.5 8.00003H8.5C8.22386 8.00003 8 7.77617 8 7.50003V6.50003Z" />
      <path d="M3 12.5C3 12.2239 3.22386 12 3.5 12H20.5C20.7761 12 21 12.2239 21 12.5V13.5C21 13.7762 20.7761 14 20.5 14H3.5C3.22386 14 3 13.7762 3 13.5V12.5Z" />
      <path d="M3 18.5C3 18.2239 3.22386 18 3.5 18H20.5C20.7761 18 21 18.2239 21 18.5V19.5C21 19.7762 20.7761 20 20.5 20H3.5C3.22386 20 3 19.7762 3 19.5V18.5Z" />
      <path d="M5.68798 6.79201C5.83641 6.89097 5.83641 7.10908 5.68798 7.20804L3.38868 8.74091C3.22254 8.85167 3 8.73257 3 8.5329L3 5.46716C3 5.26748 3.22254 5.14838 3.38867 5.25914L5.68798 6.79201Z" />
    </svg>
  );
};

export default Icon;
