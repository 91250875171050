import { FC } from "react";

import { AddButton } from "@components/Buttons";
import Listbox, {
  ListboxOption,
  useSimpleSearchProps,
} from "@components/Listbox";
import Option, { OptionType } from "@components/Select/Option";

export interface SelectProps {
  className?: string;
  label?: string;
  placeholder?: string;
  options: OptionType[];
  value: string | number;
  onChange?: (...event: any[]) => void;
  clearable?: boolean;
  simpleSearchPlaceholder?: string;
}

const Select: FC<SelectProps> = ({
  className,
  label,
  placeholder = "Choose an option",
  options,
  value,
  onChange,
  clearable = false,
  simpleSearchPlaceholder,
}) => {
  const { filteredOptions, searchProps } = useSimpleSearchProps(
    options,
    simpleSearchPlaceholder
  );

  return (
    <Listbox
      className={className}
      onChange={onChange}
      label={label}
      searchProps={searchProps}
      placeholder={(() => {
        if (value && options?.length > 0) {
          const option = options.find(
            (option: OptionType) => option?.value === value
          );
          if (option) return <Option option={option} />;
        }
        return options[0]?.icon ? (
          <AddButton className="py-4 !border-none">{placeholder}</AddButton>
        ) : (
          <p className="text-grey-500 p-2">{placeholder}</p>
        );
      })()}
      value={value}
      clearable={clearable}
      customMenuWrapperClassNames="bg-grey-950"
    >
      {filteredOptions?.map((option: OptionType) => (
        <ListboxOption
          key={option?.value}
          value={option?.value}
          colors={{ default: "bg-grey-950" }}
        >
          <Option option={option} />
        </ListboxOption>
      ))}
    </Listbox>
  );
};

export default Select;
