import { FC } from "react";
import classNames from "classnames";

interface LabelDotProps {
  color: string;
  size?: "default" | "small";
  className?: string;
}

const LabelDot: FC<LabelDotProps> = ({
  color,
  size = "default",
  className,
}) => {
  const sizes = {
    default: "w-3 h-3",
    small: "w-2 h-2",
  };
  return (
    <span
      className={classNames(
        "rounded-full text-white text-xs bg-peach-600 inline-flex items-center justify-center self-center",
        sizes[size],
        className
      )}
      style={{ backgroundColor: color }}
    />
  );
};

export default LabelDot;
