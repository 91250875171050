import { orderBy } from "lodash";

import { displayNameFromContact } from "@lib/contacts";

import { FilterByItemType, SortByItemType } from "@components/SortDropdown";

export const sortByDisplayName = (items) =>
  orderBy(items, [(item) => displayNameFromContact(item)?.toLowerCase()]);

export const sortByTitle = (items) =>
  orderBy(items, [(item) => item?.title?.toLowerCase()]);

export const COMMON_SORT_OPTIONS: SortByItemType[] = [
  {
    text: "Last created",
    value: "last-created",
    transform: (items) => orderBy(items, (item) => item.createdAt, ["desc"]),
  },
  {
    text: "Last updated",
    value: "last-updated",
    transform: (items) => orderBy(items, (item) => item.updatedAt, ["desc"]),
  },
  {
    text: "A-Z",
    value: "a-z",
    transform: sortByTitle,
  },
];

export const getStatusFilter = (
  text: string,
  status: string,
  key: string,
  defaultValue: boolean
) =>
  ({
    text,
    transform: (items: any[], value: boolean) =>
      items.filter((x) => value || x.status !== status),
    key,
    defaultValue,
  }) as FilterByItemType;
