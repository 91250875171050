import { formatEventShareTitle } from "@lib/appointments";
import { displayNameFromContact } from "@lib/contacts";

import {
  GetFormattedlinkType,
  GetTemplateBodyType,
  GetTypeSubjectType,
} from "./types";

export const getTypeSubject: GetTypeSubjectType = (type, custom) => {
  switch (type) {
    case "file":
      return `I'd like to share ${custom?.fileName || ""} with you`;
    case "link":
      return `I'd like to share this link with you: ${custom?.title || ""}`;
    case "form":
      return `Please complete this ${custom?.title || ""} form`;
    case "invoice":
    case "product":
      return `${custom?.title || ""} has been shared with you`;
    case "package":
      return `My coaching package ${custom?.title || ""}`;
    case "event":
      return `Join me for ${formatEventShareTitle(custom)}`;
    case "scheduler":
      return "Please book a new appointment with me";
    case "chat invite":
      return `Invitation to client portal`;
    default:
      return `New ${type}`;
  }
};

export const getTagLink = (url: string, label?: string) =>
  `<a href="${url}">${label || url}</a>`;

export const getFormattedlink: GetFormattedlinkType = (url, type, artifact) => {
  switch (type) {
    case "file":
      return getTagLink(url, artifact?.fileName || type);
    case "link":
    case "form":
    case "invoice":
    case "event":
    case "note":
    case "product":
    case "package":
    case "scheduler":
      return getTagLink(url, artifact?.title || type);
    case "chat invite":
      return getTagLink(url, "Open private workspace");
    default:
      return url ? getTagLink(url) : "";
  }
};

export const getTemplateBody: GetTemplateBodyType = ({
  coach,
  type,
  artifact,
  artifactLink,
  bodyOnly = false,
  isMessaging = false,
}) => {
  let body: string;
  const link = artifact
    ? isMessaging
      ? artifactLink
      : getFormattedlink(artifactLink, type, artifact)
    : "";
  const fullLinkUrl = getFormattedlink(artifactLink);
  const signOff = [
    "Please let me know if you have any questions.\n\n",
    "Thank you,\n",
    displayNameFromContact(coach, true),
    "\n\n",
  ].join("");

  switch (type) {
    case "product":
      body = `I’d like to share this product with you:\n${link}\n`;
      break;
    case "package":
      body = `Here’s a link to my package: ${link}\n`;
      break;
    case "file":
      body = `Here’s a link to the file I’d like to share with you:\n${link}\n`;
      break;
    case "scheduler":
      body = `Please use this link to book an appointment with me: ${link}`;
      break;
    case "event":
      body = [
        "I’d like to invite you to an upcoming event:\n\n",
        "<ul>",
        `<li>${formatEventShareTitle(artifact, true)}</li>`,
        `<li>${formatEventShareTitle(artifact, false, true)}</li>`,
        "</ul>",
        `\n\nRegister here: ${fullLinkUrl}\n`,
      ].join("");
      break;
    case "chat invite":
      body = `Please use this link to join your client portal: ${getFormattedlink(
        artifactLink,
        type,
        artifact
      )}`;
      break;
    default:
      body = `Here is the ${type} I would like to share with you: ${link}\n`;
  }

  if (bodyOnly) return body;
  return ["Hello,", body, signOff].join("\n\n");
};
