import { useCallback, useEffect, useState } from "react";
import { pull } from "lodash";
import { getFuego, useDocument } from "swr-firebase";

import useLabels from "@hooks/use-labels";
import { useIsMobile } from "@hooks/use-window-size";
import { useUpdateContactsCache } from "@hooks/useUpdateContactsCache";

export const useLabelsSelector = ({
  userId,
  contact,
  ref,
}: {
  userId?: string;
  contact?: any;
  ref: any;
}) => {
  const [selectorIsOpen, setSelectorIsOpen] = useState(false);
  const [selectorPosition, setSelectorPosition] = useState(undefined);
  const { labels } = useLabels(userId);
  const { labels: contactLabelsIds = [], id: contactId } = contact;
  const { update } = useDocument(`users/${userId}/clients/${contactId}`);
  const isMobile = useIsMobile();
  const contactLabels = labels?.filter(({ id }) =>
    contactLabelsIds.includes(id)
  );
  const [updating, setUpdating] = useState<string | null>(null);
  const { updateCache } = useUpdateContactsCache();

  const toggleLabel = async (label: string) => {
    setUpdating(label);
    const newLabels = contactLabelsIds.includes(label)
      ? pull(contactLabelsIds, label)
      : [...contactLabelsIds, label];

    await update({ labels: newLabels });
    updateCache([
      {
        contactId,
        data: { labels: newLabels },
      },
    ]);
    setUpdating(null);
  };

  const createLabel = async (data) => {
    const now = new Date();
    const { id } = await getFuego()
      .db.collection(`users/${userId}/labels`)
      .add({
        ...data,
        status: "active",
        createdAt: now,
        updatedAt: now,
      });
    toggleLabel(id);
  };

  const toggleSelector = useCallback(
    (e) => {
      if (ref) {
        setTimeout(
          () => setSelectorIsOpen(ref.current?.contains(e.target)),
          100
        );
      }
    },
    [ref]
  );

  useEffect(() => {
    if (ref) {
      document.addEventListener("mousedown", toggleSelector);
      setSelectorPosition(undefined, isMobile);
    }
    return () => {
      if (ref) {
        document.removeEventListener("mousedown", toggleSelector);
      }
    };
  }, [toggleSelector, isMobile]);

  return {
    selectorIsOpen,
    setSelectorIsOpen,
    selectorPosition,
    setSelectorPosition,
    createLabel,
    contactLabels,
    toggleLabel,
    labels,
    contactLabelsIds,
    updating,
  };
};
