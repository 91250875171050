import { useEffect, useState } from "react";
import { isEqual } from "lodash";

const useDebounce = <T>(value: T, delay: number) => {
  const [currentValue, setCurrentValue] = useState<T>(value);

  useEffect(() => {
    const hasChanged = !isEqual(currentValue, value);
    const timeout = setTimeout(() => {
      setCurrentValue(value);
    }, delay);

    if (!hasChanged) {
      clearTimeout(timeout);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [value, delay]);

  return currentValue;
};

export default useDebounce;
