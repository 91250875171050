import { deleteDeleteClient } from "api-services/definitions/clients";
import { useApi, UseApiConfig } from "api-services/endpoints";
import { AxiosResponse } from "axios";

import { useUpdateContactsCache } from "@hooks/useUpdateContactsCache";

type UseDeleteClientType = (props: {
  oid: string | undefined;
  clientId: string | undefined;
  mutate?: (cache?: any) => void;
}) => {
  deleteClient: () => Promise<AxiosResponse<{ status: string }> | undefined>;
  isLoadingDelete: boolean;
};

const useDeleteClient: UseDeleteClientType = ({ oid, clientId }) => {
  const apiCallOpts: UseApiConfig = {
    failMode: "throw",
  };

  const { apiCall: deleteClientApi, loading: isLoadingDelete } = useApi(
    deleteDeleteClient,
    apiCallOpts
  );
  const { updateCache } = useUpdateContactsCache();

  const deleteClient = async () => {
    if (!oid || !clientId) return;

    const response = await deleteClientApi(
      {
        apiVersion: "v1",
        userId: oid,
        clientId,
      },
      undefined,
      undefined
    );
    updateCache([{ contactId: clientId, data: { status: "deleted" } }]);
    return response;
  };

  return {
    deleteClient,
    isLoadingDelete,
  };
};

export default useDeleteClient;
