import { useEffect } from "react";
import { SanitizedAccountType } from "api-services/definitions/integrations";
import { DateTime } from "luxon";
import useSWR from "swr";

import fetchJSON from "@lib/fetch-json";
import { SchedulerType } from "@lib/shared-types";

import { SelectedMember } from "@components/AppointmentTypeForm/RoundRobinSettings/MembersInput";

import useFlow from "./use-flow";

interface SlotType {
  ISOslot: string;
  pretty: string;
  members?: SelectedMember[];
}

interface SlotDayType {
  prettyDate: string;
  slots: SlotType[];
}

interface EventType {
  color: string;
  end: string;
  start: string;
  title: string;
  type: string;
}

type AvailabilityType = Omit<EventType, "title"> & {
  members: SelectedMember[];
};

interface DataType {
  ISOslots: string[];
  allEvents: EventType[];
  availabilities: AvailabilityType[];
  scheduler: Partial<SchedulerType>;
  inactive?: boolean;
  members?: SanitizedAccountType[];
}

type UseSchedulerType = (
  userId: string | undefined,
  schedulerId: string | undefined,
  sanitizedDateFromBookingCalendar: any,
  clientId: string | undefined,
  timeZone: string | undefined,
  packageCycleDates?: { startDate: Date; endDate: Date } | null,
  roundRobin?: boolean
) => {
  data?: DataType;
  scheduler?: Partial<SchedulerType>;
  error: any;
  getSlotDays: () => SlotDayType[];
  loading: boolean;
  inactive?: boolean;
};

const useScheduler: UseSchedulerType = (
  userId,
  schedulerId,
  sanitizedDateFromBookingCalendar,
  clientId = "",
  timeZone = undefined,
  packageCycleDates = null,
  roundRobin
) => {
  const timeStampYear = sanitizedDateFromBookingCalendar?.c.year;
  const timeStampMonth = sanitizedDateFromBookingCalendar?.c.month;
  const { sharedData: schedulerData } = useFlow();
  const memberId = schedulerData?.memberId as string;

  const params = new URLSearchParams();
  if (timeStampYear) params.append("timeStampYear", timeStampYear);
  if (timeStampMonth) params.append("timeStampMonth", timeStampMonth);
  if (clientId) params.append("clientId", clientId);
  if (memberId) params.append("memberId", memberId);
  if (packageCycleDates) {
    const { startDate, endDate } = packageCycleDates;
    if (startDate)
      params.append("packageCycleStartDate", startDate as unknown as string);
    if (endDate)
      params.append("packageCycleEndDate", endDate as unknown as string);
  }

  const url =
    userId && schedulerId && (!roundRobin || memberId !== undefined)
      ? `/api/users/${userId}/book/${schedulerId}?${params.toString()}`
      : null;
  const { data, error } = useSWR<DataType>(url, fetchJSON, {
    dedupingInterval: 60_000,
  });

  useEffect(() => {
    if (error) {
      console.error("Scheduler Error", schedulerId);
    }
  }, [error, userId, schedulerId]);

  const { ISOslots = [], scheduler, availabilities = [] } = data || {};
  const loading = !data;

  const inactive =
    (scheduler?.status && scheduler.status === "inactive") || data?.inactive;

  function getSlotDays() {
    const slotDays: SlotDayType[] = [];
    const now = DateTime.local().setZone(timeZone);

    ISOslots.forEach((ISOslot: string, index: number) => {
      // find the day of the current slot
      const startTime = DateTime.fromISO(ISOslot).setZone(timeZone);
      const prettyDate = startTime.toFormat("cccc LLLL d, yyyy");
      let slotDay = slotDays.find(
        (slotDay) => slotDay.prettyDate === prettyDate
      );
      if (!slotDay) {
        slotDay = { prettyDate, slots: [] };
        slotDays.push(slotDay);
      }
      const pretty = startTime.toFormat("h:mm a");
      if (startTime > now) {
        if (
          memberId &&
          availabilities[index].members
            .map((member) => member.id)
            .includes(memberId as string)
        ) {
          slotDay.slots.push({
            ISOslot,
            pretty,
          });
        } else if (!memberId) {
          slotDay.slots.push({
            ISOslot,
            pretty,
            members: availabilities[index].members,
          });
        }
      }
    });

    return slotDays.filter((slotDay) => slotDay.slots.length > 0);
  }

  return {
    data,
    scheduler,
    error,
    getSlotDays,
    loading,
    inactive,
  };
};

export default useScheduler;
