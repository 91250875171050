import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const PackageIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.13546 6.73017C3.20273 7.32142 3.20705 8.68378 4.14354 9.26908L11.2049 13.6824C11.6913 13.9865 12.3085 13.9865 12.7949 13.6824L19.8563 9.26909C20.7928 8.68379 20.7971 7.32142 19.8644 6.73018L12.803 2.25408C12.3127 1.9433 11.6871 1.9433 11.1968 2.25408L4.13546 6.73017ZM11.9999 11.8208L5.87715 7.9941L11.9999 4.11299L18.1227 7.9941L11.9999 11.8208Z"
      />
      <path d="M3.15189 11.4701C3.4446 11.0017 4.06155 10.8594 4.52989 11.1521L11.9999 15.8208L19.4699 11.1521C19.9382 10.8594 20.5552 11.0017 20.8479 11.4701C21.1406 11.9384 20.9982 12.5554 20.5299 12.8481L12.7949 17.6824C12.3085 17.9865 11.6913 17.9865 11.2049 17.6824L3.46989 12.8481C3.00155 12.5554 2.85918 11.9384 3.15189 11.4701Z" />
      <path d="M3.15189 15.4701C3.4446 15.0017 4.06155 14.8594 4.52989 15.1521L11.9999 19.8208L19.4699 15.1521C19.9382 14.8594 20.5552 15.0017 20.8479 15.4701C21.1406 15.9384 20.9982 16.5554 20.5299 16.8481L12.7949 21.6824C12.3085 21.9865 11.6913 21.9865 11.2049 21.6824L3.46989 16.8481C3.00155 16.5554 2.85918 15.9384 3.15189 15.4701Z" />
    </svg>
  );
};

export default PackageIcon;
