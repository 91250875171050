import { difference, orderBy, random } from "lodash";

export const colors = [
  "#F0ABAB",
  "#C35560",
  "#60202F",
  "#FAD89E",
  "#CC8033",
  "#76410A",
  "#BFCCE2",
  "#4C70AE",
  "#021C4A",
  "#C6D2C8",
  "#6E8970",
  "#033B0F",
];

export const getRandomizedColor = (labels) => {
  const labelsColorsApplied = labels.map(({ color }) => color);
  const diffColors = difference(colors, labelsColorsApplied);
  if (diffColors.length) {
    return diffColors[random(0, diffColors.length - 1)];
  } else {
    return colors[random(0, colors.length - 1)];
  }
};

export const defaultLabelsOrder = {
  Lead: 1,
  Prospect: 2,
  Qualified: 3,
  Onboarded: 4,
  Subscribed: 5,
  Inactive: 6,
};

export const getOrderedLabels = (labels) => {
  return orderBy(
    labels.map((label) => {
      const order = defaultLabelsOrder[label.title];
      return { ...label, order: order ? order : 100 };
    }),
    ["order"]
  );
};

export const getOffsetPosition = ({
  e,
  isMobile,
  buttonWidth,
  buttonHeight,
  forceRightSide = false,
}) => {
  const rect = e.target.getBoundingClientRect();
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const boxWidth = 430;
  const boxHeight = 312;
  const mobileSpace = 16;

  const leftChanged = rect?.left - (boxWidth - buttonWidth);
  const leftPosition =
    screenWidth - rect?.left < boxWidth || forceRightSide
      ? leftChanged
      : rect?.left;

  const topChanged = rect?.top - (boxHeight + buttonHeight);
  const topPosition =
    screenHeight - rect?.top < boxHeight ? topChanged : rect?.top;

  return {
    left: isMobile ? mobileSpace : leftPosition,
    top: isMobile ? mobileSpace : topPosition,
    ...(isMobile && {
      right: mobileSpace,
      width: 350,
      maxWidth: "none",
      margin: "auto",
    }),
  };
};
