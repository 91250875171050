import classNames from "classnames";

import useContact from "@hooks/use-contact";
import { ClientType } from "@lib/data/schemas/client";
import { GroupMemberType } from "@lib/shared-types";
import pluralHelper from "@lib/utils/pluralHelper";

import ClientAvatar from "@components/Client/ClientAvatar";

interface MemberSubtitleInterface {
  members: Array<GroupMemberType>;
  className?: string;
  showAvatars?: boolean;
  color: { background: string; foreground: string };
}

const MemberSubtitle: React.FC<MemberSubtitleInterface> = ({
  members,
  className,
  showAvatars,
  color,
}) => {
  interface AvatarProps {
    clientId?: string;
    remainingClients?: number;
  }
  const Avatar = ({ clientId, remainingClients }: AvatarProps) => {
    const { contact } = useContact(clientId);
    return remainingClients ? (
      <ClientAvatar
        className={classNames("mx-0 -mr-3 z-10", `className`)}
        size="xsmall"
        text={`+${remainingClients}`}
        client={{}}
        customStyle={{
          backgroundColor: color?.background,
          color: color?.foreground,
        }}
      />
    ) : (
      <ClientAvatar
        className="mx-0 -mr-3 z-10"
        size="xsmall"
        client={contact as ClientType}
      />
    );
  };
  const length = members?.length;
  const firstTwo = members?.slice(0, 2);
  return (
    <div className={classNames("flex items-center -mt-1", className)}>
      <div className="flex">
        {showAvatars && (
          <>
            {firstTwo.map((member) => (
              <div key={member.clientId}>
                <Avatar clientId={member.clientId} />
              </div>
            ))}
            {length > 2 && <Avatar remainingClients={length - 2} />}
          </>
        )}
      </div>
      <div className={showAvatars && length > 0 && "ml-6"}>
        {pluralHelper(length, "client")}
      </div>
    </div>
  );
};

export default MemberSubtitle;
