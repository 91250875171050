import React, { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import classNames from "classnames";

import { useAuth } from "@contexts/auth";
import useEmailSignature from "@hooks/use-email-signature";
import { getCoachEmails } from "@lib/utils/email";

import Toggle from "@components/Form/Toggle";
import LoadingSpinner from "@components/LoadingSpinner";

interface ToggleSignatureProps {
  className?: string;
  onChange?: (value: boolean) => void;
  contentContainingQuote?: string;
}

const ToggleSignature: FC<ToggleSignatureProps> = ({
  className,
  onChange,
  contentContainingQuote,
}) => {
  const { account, oid, aid } = useAuth();
  const coachEmails = getCoachEmails(account);
  const {
    signature,
    withSignature,
    concatSignature,
    isEmptySignature,
    setWithSignature,
    isSignatureLoading,
  } = useEmailSignature({ userId: oid, email: coachEmails[0], memberId: aid });
  const { watch, setValue } = useFormContext();
  const bodyValue = watch("body");

  const quoteIndex =
    bodyValue.indexOf("<blockquote>") !== -1 &&
    bodyValue.indexOf("<blockquote>");
  const nonQuoteContent = quoteIndex
    ? bodyValue.slice(0, quoteIndex)
    : bodyValue;
  const quoteContent = quoteIndex ? bodyValue.slice(quoteIndex) : "";
  const hasBodySignature = nonQuoteContent?.includes("--");

  useEffect(() => {
    if (withSignature && !isEmptySignature) {
      let body;
      if (contentContainingQuote) {
        body = concatSignature(nonQuoteContent)?.concat(quoteContent);
      } else {
        body = concatSignature(bodyValue);
      }
      if (!hasBodySignature) {
        setValue("body", body);
      }
    }
    if (!withSignature && hasBodySignature && signature) {
      const withoutSigContent = nonQuoteContent.split("--")[0];
      const newBody = quoteContent
        ? withoutSigContent.concat(quoteContent)
        : withoutSigContent;
      setValue("body", newBody);
    }
  }, [withSignature, signature]);

  const handleChange = (value: boolean) => {
    setWithSignature(value);
    onChange && onChange(value);
  };

  return (
    <div className={classNames("flex items-center", className)}>
      {signature && (
        <p className="font-medium text-black-ink mr-2 mb-1">Email signature</p>
      )}
      {isSignatureLoading && <LoadingSpinner width="24" height="24" />}
      {signature && (
        <Toggle
          onChange={handleChange}
          value={isEmptySignature ? false : withSignature}
          size="small"
        />
      )}
    </div>
  );
};

export default ToggleSignature;
