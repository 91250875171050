import React, { FC, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { drop } from "lodash";

import useAccessType from "@hooks/use-access-type";
import { LabelType } from "@lib/data/schemas/label";
import { sortAlphabetically } from "@lib/utils";

import { HighlightedTextFromContext } from "@components/HighlightedText";
import CloseIcon from "@components/Icons/CloseIcon";

import LabelDot from "./LabelDot";

interface LabelsListProps {
  labels: LabelType[];
  className?: string;
  itemClassName?: string;
  onClickRemove?: (labelId: string) => void;
  onClickAdd?: () => void;
  onClickLabel?: (label: LabelType, e: React.MouseEvent) => void;
  componentAfter?: React.ReactNode;
}

const LabelsList: FC<LabelsListProps> = ({
  labels,
  className,
  itemClassName,
  onClickRemove,
  onClickAdd,
  onClickLabel,
  componentAfter,
}) => {
  const outer = useRef(null);
  const itemRefs = useRef([]);
  const [totalDisplay, setTotalDisplay] = useState(0);
  const { hasElevatedAccess } = useAccessType();
  const canEditLabels = hasElevatedAccess;

  const totalLabels = labels.length;
  const orderedLabels = sortAlphabetically(labels);
  const labelStyle = classNames(
    "flex items-center text-xs text-black-ink font-normal",
    "border border-grey-900 py-1 px-2 rounded-lg mr-2",
    onClickLabel && "hover:border-grey-800",
    itemClassName
  );

  useEffect(() => {
    const available = outer.current.clientWidth - 65;
    let sum = 0;

    const getVisibleCount = () => {
      let i = 0;
      while (
        i < itemRefs.current.length &&
        sum + itemRefs.current[i]?.clientWidth < available
      ) {
        sum = sum + itemRefs.current[i]?.clientWidth;

        i++;
      }
      return i;
    };

    const count = getVisibleCount();

    setTotalDisplay(count);
  }, [labels]);

  const handleClickRemove = (labelId: string) => () => onClickRemove?.(labelId);

  const handleClickLabel = (label: LabelType) => (e: any) =>
    onClickLabel?.(label, e);

  const renderLabel = (label: LabelType) => (
    <div
      key={`label-list-item-${label.id}`}
      className={labelStyle}
      onClick={handleClickLabel(label)}
    >
      <LabelDot color={label.color} size="small" className="mr-2" />
      <HighlightedTextFromContext text={label.title} />
      {onClickRemove && canEditLabels && (
        <span
          onClick={handleClickRemove(label.id)}
          data-heap-event-name="contact_labels_removed"
        >
          <CloseIcon className="w-3 h-3 text-grey-500 ml-2 cursor-pointer" />
        </span>
      )}
    </div>
  );

  const renderLabelDisplay = (label: LabelType) => (
    <div
      key={`label-displat-item-${label.id}`}
      className="flex text-base mb-4 last:mb-0 w-32 items-center"
      onClick={handleClickLabel(label)}
    >
      <LabelDot color={label.color} className="mr-2" />
      <HighlightedTextFromContext text={label.title} />
      {onClickRemove && (
        <span
          onClick={handleClickRemove(label.id)}
          className="ml-auto"
          data-heap-event-name="contact_labels_removed"
        >
          <CloseIcon className="w-3 h-3 text-grey-500 ml-2 cursor-pointer" />
        </span>
      )}
    </div>
  );

  const renderShowRest = () => {
    return (
      totalLabels > totalDisplay && (
        <div className={`${labelStyle} relative group/rest`}>
          + {totalLabels - totalDisplay}
          <div className="absolute bg-white max-w-xs shadow-equal-24 rounded-lg top-7 right-0  p-4 z-10 invisible group-hover/rest:visible opacity-0 group-hover/rest:opacity-100 transition duration-500">
            {drop(orderedLabels, totalDisplay).map(renderLabelDisplay)}
          </div>
        </div>
      )
    );
  };

  const renderAddLabel = onClickAdd && (
    <div onClick={onClickAdd} className={`${labelStyle} w-7 justify-center`}>
      +
    </div>
  );

  return (
    <div
      ref={outer}
      className={classNames("flex w-full items-center", className)}
    >
      <div style={{ left: 9999 }} className="flex w-full fixed opacity-0">
        {orderedLabels.map((label, i) => (
          <div
            className="pr-2"
            ref={(el) => (itemRefs.current[i] = el)}
            key={label.id}
          >
            {renderLabel(label)}
          </div>
        ))}
      </div>
      {orderedLabels.slice(0, totalDisplay).map(renderLabel)}
      {renderShowRest()}
      {canEditLabels && renderAddLabel}
      {componentAfter}
    </div>
  );
};

export default LabelsList;
