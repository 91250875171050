import { useEffect } from "react";
import axios from "axios";
import { isEmpty } from "lodash";
import useSWR from "swr";

import analytics from "@lib/analytics";

import usePersistentState from "./use-persistent-state";
import useSnackbar from "./use-snackbar";

type TrackType = (
  event: string,
  properties?: { [key: string]: boolean }
) => void;
type UseEmailSignatureType = (props: {
  userId: string;
  email?: string;
  memberId: string;
}) => {
  signature: string;
  withSignature: boolean;
  setWithSignature: (data: boolean) => void;
  concatSignature: (data: string) => string | undefined;
  isSignatureLoading: boolean;
  isEmptySignature: boolean;
  track: TrackType;
};

const useEmailSignature: UseEmailSignatureType = ({
  userId,
  email,
  memberId,
}) => {
  const snackbar = useSnackbar();
  const {
    persistentValue: withSignature,
    persistentSetValue: setWithSignature,
  } = usePersistentState<boolean>("withEmailSignature", false);
  const url = `/api/v1/gmail/${userId}/signature?memberId=${memberId}`;
  const args = {
    email,
  };

  const { data, error } = useSWR(
    email ? url : null,
    (url) => axios.post(url, args).then((res) => res.data),
    { dedupingInterval: 60000 }
  );

  const signature = data?.signature;

  useEffect(() => {
    if (error) {
      snackbar.showWarning(
        "Could not load your email signature",
        "Try reconnecting your Gmail account if this issue persists."
      );

      console.error(error);
    }
  }, [error]);

  const concatSignature = (content: string) => {
    if (!isEmpty(signature)) {
      return [
        isEmpty(content) ? `<br>${content}` : content,
        `--<br /><br />${signature}`,
      ].join("");
    }
  };

  const track: TrackType = (event, properties = {}) => {
    analytics.track({
      event,
      properties: {
        withSignature: !!withSignature,
        ...properties,
      },
    });
  };

  return {
    signature,
    withSignature,
    setWithSignature,
    concatSignature,
    isSignatureLoading: !data,
    isEmptySignature: isEmpty(signature),
    track,
  };
};

export default useEmailSignature;
