import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const NoteIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.00002 11.4853L16.5962 3.8891L18.7175 6.01042L11.1213 13.6066L9 13.5858L9.00002 11.4853Z" />
      <path d="M19.4246 5.30332L17.3033 3.182L18.1924 2.29289C18.5829 1.90237 19.2161 1.90237 19.6066 2.29289L20.3137 3C20.7042 3.39052 20.7042 4.02369 20.3137 4.41421L19.4246 5.30332Z" />
      <path d="M17 20V10.3335L19 8.33351V20C19 21.1046 18.1046 22 17 22H6C4.89543 22 4 21.1046 4 20V5H13.2086L11.2086 7H6V20H17Z" />
    </svg>
  );
};

export default NoteIcon;
