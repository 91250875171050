import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const GroupIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      className={classNames("fill-current", className)}
    >
      <path
        fillRule="evenodd"
        d="M12.437 5.25A3 3 0 109.003 9.83a5.003 5.003 0 013.434-4.582zm2.162-.214a5.001 5.001 0 10-5.198 6.928c.158.37.36.718.599 1.036H6a4 4 0 00-4 4v1.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V17a2 2 0 012-2h8a5 5 0 00.6-9.964zM14 7a3 3 0 100 6 3 3 0 000-6zM8 20a2 2 0 012-2h8a2 2 0 012 2v1.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V20a4 4 0 00-4-4h-8a4 4 0 00-4 4v1.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V20z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default GroupIcon;
