import { FC } from "react";
import classNames from "classnames";

import DoubleChevronCloseIcon from "@components/Icons/DoubleChevronCloseIcon";
import DoubleChevronOpenIcon from "@components/Icons/DoubleChevronOpenIcon";

interface CollapsedHeaderProps {
  toggleCollapsed: (collapsed: boolean) => void;
  collapsed: boolean;
  leftElement: React.ReactNode;
  className?: string;
  disableHeaderClickable?: boolean;
  iconClassNames?: string;
}

const CollapsedHeader: FC<CollapsedHeaderProps> = ({
  toggleCollapsed,
  collapsed,
  leftElement,
  className,
  disableHeaderClickable = false,
  iconClassNames,
}) => {
  return (
    <div
      onClick={() => !disableHeaderClickable && toggleCollapsed(!collapsed)}
      className={classNames(
        "flex justify-between items-center",
        !collapsed && "pb-2",
        !disableHeaderClickable && "cursor-pointer",
        className
      )}
    >
      {leftElement}
      <div
        className={classNames(
          disableHeaderClickable && "cursor-pointer",
          iconClassNames
        )}
        onClick={() => disableHeaderClickable && toggleCollapsed(!collapsed)}
      >
        {collapsed ? <DoubleChevronOpenIcon /> : <DoubleChevronCloseIcon />}
      </div>
    </div>
  );
};

export default CollapsedHeader;
