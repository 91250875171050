import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className, ...props }) => (
  <svg
    className={classNames("fill-current", className)}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="evenodd"
      d="M8 2H6v2H3v16a2 2 0 002 2h1.5a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5H5V6h14v1.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V4h-3V2h-2v2H8V2z"
    ></path>
    <path
      fill="evenodd"
      fillRule="evenodd"
      d="M23 16a8 8 0 10-16 0 8 8 0 0016 0zm-7.99 5.999a.05.05 0 01-.02 0 .211.211 0 01-.039-.012.853.853 0 01-.216-.143c-.216-.182-.496-.524-.775-1.082-.46-.92-.815-2.227-.925-3.762h3.93c-.11 1.535-.465 2.841-.925 3.762-.279.558-.56.9-.775 1.082a.853.853 0 01-.216.142.211.211 0 01-.038.013zM16.966 15h-3.93c.11-1.534.465-2.841.925-3.762.279-.558.56-.9.775-1.082a.853.853 0 01.216-.143A.211.211 0 0115 10a.211.211 0 01.049.014c.04.016.113.054.216.142.216.182.496.524.775 1.082.46.92.815 2.228.925 3.762zm2.004 2c-.098 1.577-.426 3.011-.914 4.165A6.005 6.005 0 0020.917 17h-1.948zm1.948-2h-1.948c-.098-1.577-.426-3.011-.914-4.165A6.005 6.005 0 0120.917 15zm-9.886 0c.098-1.577.426-3.011.914-4.165A6.005 6.005 0 009.083 15h1.948zm-1.948 2a6.005 6.005 0 002.862 4.165c-.488-1.154-.816-2.588-.914-4.165H9.083z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default Icon;
