import classNames from "classnames";

import { displayNameFromContact } from "@lib/contacts";
import { AccountType } from "@lib/data/schemas/account";

import ClientAvatar, { objectSizes } from "@components/Client/ClientAvatar";
import GroupIcon from "@components/Icons/GroupIcon";
import { getUniquePackageAccountNames } from "@components/Package/utils";

interface AccountDetailProps {
  accounts?: Partial<AccountType>[];
  accountClassNames?: string;
  hideNames?: boolean;
  showDividerBefore?: boolean;
  avatarSize?: keyof typeof objectSizes;
}
const AccountDetail: React.FC<AccountDetailProps> = ({
  accounts,
  accountClassNames,
  hideNames = false,
  showDividerBefore = false,
  avatarSize = "xsmall",
}) => {
  if (!accounts) return null;
  const multipleAccounts = accounts.length > 1;
  const singleAccount = accounts.length === 1 && accounts[0];
  const names = multipleAccounts && getUniquePackageAccountNames(accounts);

  return (
    <div className={classNames("flex items-center gap-2", accountClassNames)}>
      {showDividerBefore && <span>·</span>}
      {multipleAccounts ? (
        <>
          <div className="p-1 rounded-full bg-foreground/20">
            <GroupIcon className="h-4 w-4" />
          </div>
          {!hideNames && <span>{names}</span>}
        </>
      ) : (
        singleAccount && (
          <>
            <ClientAvatar
              size={avatarSize}
              className="mx-0"
              client={singleAccount}
            />
            <span>{displayNameFromContact(singleAccount, true)}</span>
          </>
        )
      )}
      {!showDividerBefore && <span>·</span>}
    </div>
  );
};

export default AccountDetail;
