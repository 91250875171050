import { useCallback, useEffect, useState } from "react";

import analytics from "@lib/analytics";

type UseHubspotWidgetProps = (loadOverride?: boolean) => {
  openChat: () => void;
  closeChat: () => void;
  isChatEnabled: boolean;
  hoursUntilChatOpen: number | null;
};

const useHubspotWidget: UseHubspotWidgetProps = (loadOverride = false) => {
  const [isChatEnabled, setIsChatEnabled] = useState<boolean>(false);
  const [hoursUntilChatOpen, setHoursUntilChatOpen] = useState<number | null>(
    null
  );
  const wrapper =
    typeof window === "undefined" ? null : document.getElementById("live-chat");

  const handleConversationStared = () => {
    analytics.track("live-chat-conversation-started");
  };

  /**
   * the chat should only be visible from 9:30 to 5:30 pm (EST)
   * we are doing mannually because there is no way to get this information
   * from hubspot API
   * */
  const checkIfChatIsEnabled = useCallback(() => {
    const now = new Date();

    // 9:00 am EST
    const start = new Date();
    start.setUTCHours(13);
    start.setUTCMinutes(0);
    start.setUTCSeconds(0);
    start.setUTCMilliseconds(0);

    // 5:00 pm EST
    const end = new Date();
    end.setUTCHours(21);
    end.setUTCMinutes(0);
    end.setUTCSeconds(0);
    end.setUTCMilliseconds(0);

    const isDuringOpenHours = now >= start && now <= end;
    const isWeekday = now.getUTCDay() >= 1 && now.getUTCDay() <= 5;

    // this is used to hide the widget when it manually set as hide in hubspot
    // we tried to use just this prop to control the visibility but it didn't
    // work as expected and that is the reason why we check this information
    // in the front-end
    const loaded =
      window.HubSpotConversations?.widget.status()?.loaded || loadOverride;

    setIsChatEnabled(isDuringOpenHours && isWeekday && loaded);

    if (!isDuringOpenHours) {
      const nextStart = new Date(now.getTime()); // copy current time
      nextStart.setUTCHours(13);
      nextStart.setUTCMinutes(0);
      nextStart.setUTCSeconds(0);
      nextStart.setUTCMilliseconds(0);
      if (nextStart < now) {
        nextStart.setUTCDate(now.getUTCDate() + 1);
      }

      const diff = (nextStart.getTime() - now.getTime()) / (1000 * 60 * 60);

      setHoursUntilChatOpen(Math.round(diff));
    } else {
      setHoursUntilChatOpen(null);
    }
  }, [loadOverride]);

  const handleOpen = useCallback(() => {
    if (!wrapper) return;

    wrapper.style.height = "500px";
    analytics.track("live-chat-open");
  }, [wrapper]);

  const handleClose = useCallback(() => {
    if (!wrapper) return;

    wrapper.style.height = "0px";
    analytics.track("live-chat-close");
  }, [wrapper]);

  useEffect(() => {
    const closeChat = document.getElementById("live-chat-close");
    closeChat?.addEventListener("click", () => {
      handleClose();
    });

    checkIfChatIsEnabled();

    window.HubSpotConversations?.on(
      "conversationStarted",
      handleConversationStared
    );

    return () => {
      window.HubSpotConversations?.off(
        "conversationStarted",
        handleConversationStared
      );
    };
  }, [checkIfChatIsEnabled, handleClose]);

  return {
    openChat: handleOpen,
    closeChat: handleClose,
    isChatEnabled,
    hoursUntilChatOpen,
  };
};

export default useHubspotWidget;
