import { FC, useMemo } from "react";
import { useController } from "react-hook-form";

import { displayNameFromContact } from "@lib/contacts";
import { AccountType } from "@lib/data/schemas/account";

import Avatar from "@components/Client/ClientAvatar";
import Listbox, {
  ListboxOption,
  useSimpleSearchProps,
} from "@components/Listbox";

import { SelectFormProps } from "./SelectForm/types";

interface SelectMemberOptionType {
  value: string;
  member: AccountType;
}

interface SelectFormMembersProps
  extends Pick<SelectFormProps, "control" | "errors" | "label"> {
  className?: string;
  members: AccountType[];
  name?: string;
}

const SelectFormMembers: FC<SelectFormMembersProps> = ({
  label,
  className,
  control,
  members,
  name = "memberId",
}) => {
  const options: SelectMemberOptionType[] = useMemo(
    () =>
      members.map((member) => ({
        value: member.id,
        member,
        title: `${displayNameFromContact(member)} ${member.email}`,
      })),
    [members]
  );

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ control, name });

  const formatOptionLabel = ({ member }: SelectMemberOptionType) => {
    return (
      <div className="flex items-center">
        <Avatar client={member} />
        <div className="flex-1 ml-4 overflow-hidden">
          <p className="font-medium text-black-ink text-ellipsis overflow-hidden">
            {displayNameFromContact(member)}
          </p>
          <p className="text-grey-500 text-sm">{member.email}</p>
        </div>
      </div>
    );
  };

  const { searchProps, filteredOptions } = useSimpleSearchProps(
    options,
    "Search members..."
  );

  const member = options.find((option) => option.value === value);

  return (
    <Listbox
      label={label || "Client"}
      onChange={onChange}
      placeholder={member ? formatOptionLabel(member) : "Select a member"}
      value={value}
      error={error}
      customListboxClassNames={className}
      customMenuWrapperClassNames="bg-white"
      searchProps={searchProps}
    >
      {filteredOptions?.map((member) => (
        <ListboxOption
          key={member.value}
          primaryText={displayNameFromContact(member)}
          value={member.value}
        >
          {formatOptionLabel(member)}
        </ListboxOption>
      ))}
    </Listbox>
  );
};

export default SelectFormMembers;
